import React from "react";
import { Link } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { kruValuesState, userState } from "recoil/globalStates";
import { getOrgByPrefixState } from "recoil/publicStates";
import {
  litterRegistrationState
} from "recoil/registrationStates";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Stack,
  TableContainer,
  FormControl,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Launch } from "@mui/icons-material";

// Local
import {
  countFemales,
  countMales,
  createHandleInputChange,
  displayDate,
  getKruValueLabel,
  getNameByLang,
  getPaymentAmount,
} from "constants/helpers";


export default function LitterSummary() {
  const { t, i18n } = useTranslation();
  const { organizationPrefix } = useParams();
  const org = useRecoilValue(getOrgByPrefixState(organizationPrefix));
  const language = i18n.language;
  const kruValues = useRecoilValue(kruValuesState);
  const [formData, setFormData] = useRecoilState(litterRegistrationState);
  const { attachments } = formData;

  const methods = useFormContext();
  const { control } = methods;

  const handleInputChange = createHandleInputChange(setFormData);

  const user = useRecoilValue(userState);
  const breeder = formData.breeder ? formData.breeder : user;

  // Set price. Note it will be null when the form refreshes
  // just after submission.
  const amt  = getPaymentAmount(user, "litter", org);
  const pricePerPuppy = !amt ? 0 : pricePerPuppy.toFixed(2);
  const len = formData.puppies?.length ? formData.puppies.length : 0;
  const totalPrice = (len * pricePerPuppy).toFixed(2);

  const imageAttachments = formData.attachments.images || [];
  const fileAttachments = formData.attachments.files || [];
  const linkAttachments = formData.attachments.links || [];
  const textAttachments = formData.attachments.text || [];
  
  const displayDeclaredTypes = () => {
    if (formData.declaredBreed) return getNameByLang(formData.declaredBreed, language);
    if (formData.declaredMix) return getNameByLang(formData.declaredMix, language);
    if (formData.declaredType1 && formData.declaredType2)
      return getNameByLang(formData.declaredType1, language) + "/"
        + getNameByLang(formData.declaredType2, language) + " " + t("type mix");
    if (formData.declaredType1) return getNameByLang(formData.declaredType1, language) + " type";
    if (formData.declaredType2) return getNameByLang(formData.declaredType2, language) + " type";

    return "";
  };

  const puppycoats = formData.puppies.map(puppy => {
    return {
      coatqualities: []
        .concat(puppy.coatqualities || [])
        .concat(puppy.coatcurliness || []),
      modifiers: []
        .concat(puppy.alocusmodifiers || [])
        .concat(puppy.merlemodifiers || [])
        .concat(puppy.othermodifiers || []),
      markings: []
        .concat(puppy.whitemarkings || [])
        .concat(puppy.spots || [])
        .concat(puppy.othermarkings || [])
    };
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{t("summary")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography variant="h3">{t("breeder")}</Typography>
        </Divider>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              {breeder?.name}
              <br />
              {breeder?.address || t("noData")} {breeder?.postalCode || " "}{" "}
              {breeder?.city || " "} <br />
              {breeder?.phone || t("noData")}
              <br />
              {breeder?.email || t("noData")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            {" "}
            <Typography variant="body1">
              {t("breederPrefix")}: {breeder?.breedingPrefix || t("noData")}
              <br />
              {t("permissionToPublishName")}:{" "}
              {breeder?.permissions?.name ? t("yes") : t("no")}
              <br />
              {t("permissionToPublishCity")}:{" "}
              {breeder?.permissions?.city ? t("yes") : t("no")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("registry")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{org.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Button
          href={org.url}
          target="_blank"
          endIcon={<Launch />}
        >
          {t("registryGuidelines")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography variant="h3">{t("Sire") + " & " + t("Dam")}</Typography>
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t("sire")}</Typography>
            <Typography variant="body1">
              {formData.sire?.name || t("noData")} {formData.sire?.regNumber}
              <br />
              {t("DOB")}{" "}
              {displayDate(formData.sire?.dateOfBirth)}
            </Typography>
            <br />
          </Grid>
          <br />
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t("dam")}</Typography>
            <Typography variant="body1">
              {formData.dam?.name || t("noData")} {formData.dam?.regNumber}
              <br />
              {t("DOB")} {displayDate(formData.dam?.dateOfBirth)}
            </Typography>
            <br />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("litterInformation")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Litter name")}</TableCell>
                <TableCell>{t("dob")}</TableCell>
                <TableCell>{t("countryOfOrigin")}</TableCell>
                <TableCell>{t("countOfDeadPuppies")}</TableCell>
                <TableCell>{t("mating")}</TableCell>
                <TableCell>{t("birth")}</TableCell>
                <TableCell>{t("Breed/mix")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{formData.name || t("unnamed")}</TableCell>
                <TableCell>
                  {displayDate(formData.dateOfBirth) ||
                    t("notProvided")}
                </TableCell>
                <TableCell>
                  {formData.countryOfOrigin?.[language] || t("notProvided")}
                </TableCell>
                <TableCell>
                  {formData.stats?.deadPuppies || t("notAnswered")}
                </TableCell>
                <TableCell>
                  {t(formData.stats?.mating) || t("notAnswered")}
                </TableCell>
                <TableCell>
                  {t(formData.stats?.birth) || t("notAnswered")}
                </TableCell>
                <TableCell>
                  {displayDeclaredTypes(formData, language)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("puppies2")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("Call name")}</TableCell>
              <TableCell>{t("microchipNumber")}</TableCell>
                <TableCell>{t("sex")}</TableCell>
                <TableCell>{t("color")}</TableCell>
                <TableCell>{t("coat")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.puppies?.length ? (
                formData.puppies?.map((puppy, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{puppy.name || "-"}</TableCell>
                      <TableCell>{puppy.callname || "-"}</TableCell>
                      <TableCell>{puppy.microchipNumber || "-"}</TableCell>
                      <TableCell>{t(puppy.sex) || "-"}</TableCell>
                      <TableCell>
                        {getKruValueLabel(puppy.color, language)}
                        {(puppycoats[i].markings &&
                          puppycoats[i].markings.map((v, i) => {
                            return (
                              <div key={i}>
                                {getKruValueLabel(v, language)}
                                <br />
                              </div>
                            );
                          })) ||
                          "-"}
                        {(puppycoats[i].modifiers &&
                          puppycoats[i].modifiers.map((v, i) => {
                            return (
                              <div key={i}>
                                {getKruValueLabel(v, language)}
                                <br />
                              </div>
                            );
                          })) ||
                            "-"}
                                                  </TableCell>
                      <TableCell>
                        {getKruValueLabel(puppy.coattype, language)}
                        {(puppycoats[i].coatqualities &&
                          puppycoats[i].coatqualities.map((v, i) => {
                            return (
                              <div key={i}>
                                {getKruValueLabel(v, language)}
                                <br />
                              </div>
                            );
                          })) ||
                          "-"}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>{t("noPuppies")}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={6}>
                  <Stack
                    direction="row"
                    spacing={4}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Typography variant="body">
                      {t("males")} {countMales(formData.puppies)}
                    </Typography>
                    <Typography variant="body">
                      {t("females")} {countFemales(formData.puppies)}
                    </Typography>
                    <Typography variant="body">
                      {t("total")} {formData.puppies?.length}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("Images")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableBody>
              {formData.puppies.map((puppy, i) => (
                <TableRow key={i}>
                  <TableCell>{puppy.name}</TableCell>
                  <TableCell>
                    {imageAttachments[`images-${i}`] ? (
                      <img
                        src={imageAttachments[`images-${i}`].url}
                        alt={imageAttachments[`images-${i}`].name}
                        />
                    ) : (
                      <p>No profile image</p>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("attachments")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableBody>
                <TableRow>
                  <TableCell>{t("Files")}</TableCell>
                  <TableCell>
                    {fileAttachments.map((a, i) => (
                      <p key={i}>{a.name}</p>
                    )) || "-"}
                  </TableCell>
                </TableRow>
                  <TableRow>
                    <TableCell>{t("Links")}</TableCell>
                    <TableCell>
                      {linkAttachments.map((a, i) => (
                        <p key={i}>{a}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Notes")}</TableCell>
                    <TableCell>
                      {textAttachments.map((a, i) => (
                        <p key={i}>
                          {a.length > 50 ? `${a.substring(0, 40)}...` : a}
                        </p> 
                      )) || "-"}
                    </TableCell>
                  </TableRow>
{/*  IMAGES PER PUPPY HERE
                  {imageAttachments.length > 0 && (
                  <TableRow>
                    <TableCell>{t("Profile photos")}</TableCell>
                    <TableCell>
                      {imageAttachments.map((a, i) => (
                        <img key={i} src={a.url} alt={a.name} />
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                  */ }

              </TableBody>
          </Table>
        </TableContainer>
      </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h3">{t("termsAndPayment")}</Typography>
            </Divider>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("description")}</TableCell>
                    <TableCell>{t("pricePerPuppy")}</TableCell>
                    <TableCell>{t("numberOfPuppies")}</TableCell>
                    <TableCell>{t("total")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{t("litterRegistration")}</TableCell>
                    <TableCell>{pricePerPuppy} USD</TableCell>
                    <TableCell>{formData.puppies?.length}</TableCell>
                    <TableCell>{totalPrice} USD</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="right">
                      <Typography variant="caption">
                      {t("Payment will be collected by registering organization when your registrations are approved.")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Stack spacing={2} sx={{ mt: 3 }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Controller
                      name="dataVerified"
                      control={control}
                      defaultValue={formData?.dataVerified}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          value={formData.dataVerified}
                          onChange={(e) => handleInputChange(e, field)}
                          checked={field.value}
                        />
                      )}
                    />
                  }
                  label={t("breederConfirmation")}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Controller
                      name="orgAgreement"
                      control={control}
                      defaultValue={formData.orgAgreement}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          value={formData.orgAgreement}
                          onChange={(e) => handleInputChange(e, field)}
                          checked={field.value}
                        />
                      )}
                    />
                  }
                  label={t("termsConfirmation", {
                    registryName: org.name,
                  })}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Controller
                      name="platformAgreement"
                      control={control}
                      defaultValue={formData.platformAgreement}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          value={formData.platformAgreement}
                          onChange={(e) => handleInputChange(e, field)}
                          checked={field.value}
                        />
                      )}
                    />
                  }
                  label={
                    <>
                      {t("I have read and agreed to the ")}
                      <Link to="/terms" target="_blank" rel="noopener noreferrer">{t("FDD terms of use.")}</Link>
                    </>
                  }
              />
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
  );
}
