import React from "react";

import { Grid } from "@mui/material";

// Local
import TreedogInformation from "./formComponents/treedogInformation";
import TreedogRegistry from "./formComponents/treedogRegistry";

export default function TreedogAdditionForm() {
  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TreedogRegistry />
        </Grid>
        <Grid item xs={12}>
          <TreedogInformation />
        </Grid>
      </Grid>
    </>
  );
}
