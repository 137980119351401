import React from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
// Local
import {
  FileAttachments,
  LinkAttachments,
  NoteAttachments,
} from "components/Attachments";

export default function DogInformationAttachments ({}) {
  const { t } = useTranslation();

return (
<Grid container>
  <Grid item xs={12} sm={12}>
    <Box mb={2}>
      <Typography variant="body">{t("Attach additional documents here, such as health results or pedigree information.")}</Typography>
    </Box>
    </Grid>
    <Grid item xs={12} sm={12} mb={2}>
      <FileAttachments recoilState={dogRegistrationState} />
    </Grid>

    <Grid item xs={12} sm={12}>
      <LinkAttachments recoilState={dogRegistrationState} />
    </Grid>

    <Grid item xs={12} sm={12}>
      <NoteAttachments recoilState={dogRegistrationState} />
    </Grid>
  </Grid>
);
}