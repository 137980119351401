import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { getOrgByPrefixState } from "recoil/publicStates";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import {
  Button,
  Grid,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";
import { Launch } from "@mui/icons-material";
import { styled } from "@mui/material/styles";


const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
}));

// Local
import { createHandleInputChange } from "constants/helpers";

export default function treedogRegistry() {
  const { organizationPrefix } = useParams();
  const org = useRecoilValue(getOrgByPrefixState(organizationPrefix));

  if (!org) {
    throw new Error(`No organization found with prefix ${organizationPrefix}`);
  }

  const { t } = useTranslation();
  const location = useLocation();
  const targetPath = `/organization/${org.prefix}`;
  const [formData, setFormData] = useRecoilState(treedogAdditionState);
  const methods = useFormContext();
  const {
    control,
    formState: { errors, isSubmitting, isDirty },
  } = methods;
  const handleInputChange = createHandleInputChange(setFormData);
  
  return (
    <>
    <Paper sx={{ mb: 0, p: 3 }}>
      <Grid
        container
        sx={{ mb: 0 }}
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12} sm={9}>
       <Typography variant="h1">
        {location.pathname === targetPath ? (
          org.name
        ) : (
          <StyledListButton href={targetPath} style={{ textDecoration: 'none' }}>
            {org.name}
          </StyledListButton>
        )}
      </Typography>
           <Typography variant="body1">
            {org.url && <Link href={org.url} style={{ textDecoration: 'none' }}>{org.url}</Link>}
          </Typography>
  
        </Grid>
    </Grid>
  </Paper>
</>
  );
}
