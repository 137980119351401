import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { updateUser } from "api/privateRoutes";

import SaveIcon from "@mui/icons-material/Save";
import { Divider, InputLabel, styled } from "@mui/material";

import {
  Typography,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  Select,
  Button,
  Alert,
} from "@mui/material";
import CountryAutocomplete from "../formComponents/countryAutocomplete";
import { countries } from "constants/countries";
import { ManageAccounts, Person } from "@mui/icons-material";

const NumberInput = styled(TextField)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

export default function PersonData({ user, accessToken }) {
  const { t } = useTranslation();
  const [showSubmit, setShowSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    address: user?.address || "",
    postalCode: user?.postalCode || "",
    city: user?.city || "",
    country: user?.country || "US",
    permissions: {
      name: user?.permissions?.name || false,
      city: user?.permissions?.city || false,
      country: user?.permissions?.country || false,
    },
    language: user?.language || "",
  });

  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((c) => c.code === user.country?.toUpperCase()) || ""
  );

  const handleChange = (event) => {
    setUpdatedUser({
      ...updatedUser,
      [event.target.name]: event.target.value,
    });
    setShowSubmit(true);
    setUpdateSuccessful(false);
  };

  // Separate handler for permissions, because it is a nested object
  const handlePermChange = (event) => {
    setUpdatedUser({
      ...updatedUser,
      permissions: {
        ...updatedUser.permissions,
        [event.target.name]: event.target.value,
      },
    });
    setShowSubmit(true);
    setUpdateSuccessful(false);
  };

  const handleAutocompleteChange = (event, newValue, name) => {
    if (name === "country") {
      setSelectedCountry(newValue);
      if (newValue) {
        setUpdatedUser({
          ...updatedUser,
          country: newValue.code,
        });
      }
    }
    setShowSubmit(true);
  };

  const handleSubmit = () => {
    const fetchData = async () => {
      await updateUser(updatedUser, { accessToken }).then((res) => {
        if (res.error) {
          setError({ error: true, msg: res.message });
        } else {
          setUpdatedUser(res);
          setShowSubmit(false);
          setUpdateSuccessful(true);
          setError(false);
          setTimeout((window.location = `/user`), 3000);
        }
      });
    };
    fetchData();
  };

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography
            variant="h2"
            sx={{ display: "flex", alignItems: "flex-start" }}
          >
            <Person sx={{ mr: 1 }} /> {t("userProfile")}
          </Typography>
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t("name")}
          name="name"
          placeholder={t("usersFullName")}
          value={updatedUser?.name || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={t("email")}
          name="email"
          placeholder={t("email")}
          value={updatedUser?.email || ""}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={t("phoneNumber")}
          name="phone"
          placeholder={t("phoneNumber")}
          type="tel"
          value={updatedUser?.phone || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t("address")}
          name="address"
          placeholder={t("address")}
          value={updatedUser?.address || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          fullWidth
          name="postalCode"
          label={t("postalCode")}
          placeholder={t("postalCode")}
          value={updatedUser?.postalCode || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={t("city")}
          name="city"
          placeholder={t("city")}
          value={updatedUser?.city || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CountryAutocomplete
          label={t("country")}
          name="country"
          placeholder={t("country")}
          handleChange={handleAutocompleteChange}
          options={countries}
          value={selectedCountry}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>{t("language")}</InputLabel>
          <Select
            id="language"
            label={t("language")}
            name="language"
            value={updatedUser?.language}
            onChange={handleChange}
          >
            <MenuItem value="fi">{t("finnish")}</MenuItem>
            <MenuItem value="en">{t("english")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography
            variant="h2"
            sx={{ display: "flex", alignItems: "flex-start" }}
          >
            <ManageAccounts sx={{ mr: 1 }} /> {t("permissions")}
          </Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>{t("permissionToPublishName")}</InputLabel>
          <Select
            id="namePerm"
            name="name"
            label={t("permissionToPublishName")}
            value={updatedUser?.permissions?.name}
            onChange={handlePermChange}
          >
            <MenuItem value={true}>{t("yes")}</MenuItem>
            <MenuItem value={false}>{t("no")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>{t("permissionToPublishCity")}</InputLabel>

          <Select
            id="cityPerm"
            name="city"
            label={t("permissionToPublishCity")}
            value={updatedUser?.permissions?.city}
            onChange={handlePermChange}
          >
            <MenuItem value={true}>{t("yes")}</MenuItem>
            <MenuItem value={false}>{t("no")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>{t("permissionToPublishCountry")}</InputLabel>

          <Select
            id="countryPerm"
            name="country"
            label={t("permissionToPublishCountry")}
            value={updatedUser?.permissions?.country}
            onChange={handlePermChange}
          >
            <MenuItem value={true}>{t("yes")}</MenuItem>
            <MenuItem value={false}>{t("no")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={12} sm={7}>
        {error.error && (
          <Alert severity="error" sx={{ width: "100%" }}>
            {t("error")}: {error.msg}
          </Alert>
        )}
        {updateSuccessful && (
          <Alert severity="success" sx={{ width: "100%" }}>
            {t("changesSavedSuccessfully")}
          </Alert>
        )}
      </Grid>
      <Grid item xs={12} sm={5}>
        <Button
          variant="contained"
          color="primary"
          disabled={!showSubmit}
          onClick={handleSubmit}
          sx={{ mr: 2, float: "right" }}
        >
          <SaveIcon fontSize="small" sx={{ mr: 1 }} /> {t("saveChanges")}
        </Button>
      </Grid>
    </>
  );
}
