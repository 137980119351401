import React from "react";

// MUI
import {
  Grid,
} from "@mui/material";
// Local
import { ImageUpload } from "components/Attachments";
import { treedogAdditionState } from "recoil/registrationStates";

export default function TreedogInformationImages({}) {
  return (
    <Grid container spacing={2}>
      <ImageUpload category="headImage" recoilState={treedogAdditionState}/>
      <ImageUpload category="sideImage" recoilState={treedogAdditionState}/>
    </Grid>
  );
}