// React
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Recoil
// import { useRecoilValue } from "recoil";
// import { accessTokenState } from "recoil/globalStates";

// MUI
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

// Local
// import { getAttachments } from "api/adminRoutes";
import { adminUrl, request } from "api/common";
import { getAttachments } from "api/publicRoutes";
// import { iconStyle } from "constants/helpers";

function handleAttachment(attachment, action = "view") {

  // For now, attachments are in a public container.
  // Eventually that container will switch to private.
  // Then they will no longer be accessible except by admins.
  // At that point be sure to remove the relevant ENV.

  // const url = adminUrl([`/attachments/byId/${attachment._id}/file`]);
  // request({ url, method: "GET", accessToken,raw: true })
  const url =  "https://" + process.env.REACT_APP_baseFileDomain + "/" + attachment.fileName;
  request({ url, method: "GET",raw: true })
    .then((response) => response.blob())
    .then((blob) => {
      // Create a objectURL from the binary file
      const file = window.URL.createObjectURL(blob);

      // Create a fake link tag so we can click it later.
      // Believe or not this is the intended way of making the browser download a file if it's not available by URL.
      const fakeLink = document.createElement("a");
      fakeLink.href = file;

      // handle downloading or viewing the file
      switch (action) {
        case "view":
          fakeLink.target = "_blank";
          break;
        case "download":
          const filename = `${attachment.category}-${attachment.parentDoc}-${attachment.originalName}`;
          fakeLink.download = filename;
          break;
        default:
          break;
      }

      // fakeLink must be appended to the DOM or it can't be clicked
      document.body.appendChild(fakeLink);
      fakeLink.click();
      fakeLink.remove();
    });
}

export default function AttachmentList({ parentDoc }) {
  const parentId = parentDoc._id;
  // TODO use admin interface again when we have health testing working
  // const accessToken = useRecoilValue(accessTokenState);

  const [loading, setLoading] = useState(true);
  const [attachments, setAttachments] = useState([]);

  const baseFileUrl =  "https://" + process.env.REACT_APP_baseFileDomain;
  const baseImageUrl = "https://" + process.env.REACT_APP_baseImageDomain;

  useEffect(() => {
    // getAttachments({ accessToken, query: { parentDoc: parentId } }).then(
      getAttachments({ query: { parentDoc: parentId } }).then(
      (data) => {
        const attachments = {
          images: [],
          files: [],
          links: [],
          text: [],
        };
        data.sort((a, b) => {
          if (a.dateAdded < b.dateAdded) return -1;
          if (a.dateAdded > b.dateAdded) return 1;
          return 0;
        });
        data.forEach((attachment) => {
          if (attachment.__t === "ImageAttachment") {
            attachments.images.push(attachment);
          } else if (attachment.__t === "FileAttachment") {
            attachments.files.push(attachment);
          } else if (attachment.__t === "LinkAttachment") {
            attachments.links.push(attachment);
          } else if (attachment.__t === "TextAttachment") {
            attachments.text.push(attachment);
          }
        });

        setAttachments(attachments);
        setLoading(false);
      }
    );
  }, [parentId]);

  if (loading) return;
  <Typography variant="body">Loading attachments...</Typography>;

  if (attachments.images.length + attachments.files.length
    + attachments.links.length + attachments.text.length === 0) {
    return <Typography variant="body">No attachments</Typography>;
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {attachments.files.map((attachment, i) => (
            <TableRow key={i}>
              <TableCell>{attachment.originalName}
                <Button
                    href={`${baseFileUrl}/${attachment.path}/${attachment.fileName}`}
                    endIcon={<LaunchIcon />}
                    size="small"
                    sx={{ float: "right" }}
                  >
                    View
                  </Button>
                </TableCell>
            </TableRow>
          ))}

          {attachments.links.map((attachment, i) => (
            <TableRow key={i}>
              <TableCell>
                <Link to={attachment.url} target="_blank" rel="noreferrer">{attachment.url}</Link>
              </TableCell>
            </TableRow>
          ))}

          {attachments.text.map((attachment, i) => (
            <TableRow key={i}>
              <TableCell>
                {attachment.text}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

