import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { attachmentsModState } from "recoil/adminStates";
import { accessTokenState } from "recoil/globalStates";
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Launch } from "@mui/icons-material";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEye, faDeleteLeft, faDownload, faGlasses } from "@fortawesome/free-solid-svg-icons";

const iconStyle = {
  color: "#0000008a",
  fontSize: "1.2rem",
};

function AdminGeneticResultsList( { dog }) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  return (
      <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Provider</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Display?</TableCell>
            <TableCell>Raw results</TableCell>
            <TableCell>Results</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dog.geneticResults && dog.geneticResults.map((geneticResult, i) => (
            <TableRow key={i}>
              <TableCell>{geneticResult.provider}</TableCell>
              <TableCell>{geneticResult.source.name}</TableCell>
              <TableCell>
                <Controller
                  name={`geneticResult.display`}
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <IconButton key={i} href="" target="_blank">
                  <Launch />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton key={i} href="" target="_blank">
                  <Launch />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

/* Main */

export default function AdminDogTesting({ dog, accessToken }) {
  const [loading, setLoading] = useState(true);
  const [attachmentsMod, setAttachmentsMod] = useRecoilState(attachmentsModState);

  const { t } = useTranslation();
      
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography variant="h5">{t("Genetic Test Results")}</Typography>
        </Divider>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={true} container justifyContent="flex-start">
            <AdminGeneticResultsList dog={dog} accessToken={accessToken} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 2 }}>
        <Typography variant="h5">{t("Health Test Results")}</Typography>
        </Divider>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={true} container justifyContent="flex-start">
            <Typography variant="body">Not yet implemented.</Typography>
          </Grid>
        </Grid>
      </Grid>
</Grid>
  );
}
