import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accessTokenState,
  loadingState,
  snackBarState,
} from "recoil/globalStates";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import {
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Upload } from "@mui/icons-material";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft, faTrash } from "@fortawesome/free-solid-svg-icons";

// Local
import { addTmpAttachment, deleteTmpAttachment } from "api/privateRoutes";
import {
  iconStyle
} from "constants/helpers";

export default function TreedogInformationAttachments ({ formMethods }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const accessToken = useRecoilValue(accessTokenState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [formData, setFormData] = useRecoilState(treedogAdditionState);
  const files = formData.attachments.files;
  const links = formData.attachments.links;
  const notes = formData.attachments.text;

  const {
    formState: { errors },
  } = formMethods;

  const DogAttachmentsFilesList = ({ formMethods }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

    const handleFileAdd = async (e) => {
      setLoading(true);
      const { name, files: uploaded } = e.target;

      // Create objects with file metadata and content
      let newFiles = [];
      for (const newFile of uploaded) {
        // If there is already an entry in newFiles with the same name, error
        if (files.find((file) => newFile.name === file.name)) {
          setSnackbarState({
            open: true,
            severity: "error",
            message: t(`File ${newFile.name} already added.`),
          });
          setLoading(false);
          return;
        }

        // Create FormData object for attachment file
        // and save it to blob storage
        const newAttach = new FormData();
        newAttach.append("attachment", newFile);
        let url = null;
        try {
          url = await addTmpAttachment(newAttach, {accessToken});
        } catch (error) {
          setSnackbarState({
            open: true,
            severity: "error",
            message: t("Failed to upload file:") + " " + error.message,
          });
          setLoading(false);
          return;
        }

        newFiles.push({
          url: url,
          name: newFile.name,
          type: newFile.type,
        });
      }

      // Append newFiles to formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        attachments: {
          ...prevFormData.attachments,
          files: [
            ...prevFormData.attachments.files,
            ...newFiles,
          ],
        },
      }));

      setLoading(false);
    };

    const handleFileDelete = async (index) => {
      setLoading(true);
      const link = files[index].url;
      try {
        await deleteTmpAttachment(link, {accessToken});
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: t("Failed to delete file from storage:") + " " + error.message,
        });
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        attachments: {
          ...prevFormData.attachments,
          files: [
            ...prevFormData.attachments.files.slice(0, index),
            ...prevFormData.attachments.files.slice(index + 1),
          ],
        },
      }));
      setLoading(false);
    };

    return (
      <>
        <Box mb={2}>
          <Typography variant="h5">{t("Files")}</Typography>
        </Box>

        {(! files || files.length == 0) && (
          <Box mb={2}>
            <Typography variant="body">{t("No files added yet.")}</Typography>
          </Box>
        )}

        {files?.map((file, index) => (
          <TableContainer key={index}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {file.name}
                  </TableCell>
                  <TableCell>
                    {file.type}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleFileDelete(index)}>
                      <FontAwesomeIcon icon={faDeleteLeft} style={iconStyle} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ))}
    <Box>
      {lessThanSmall ? (
        <Fab color="primary" size="small" component="label">
          <Upload />
          <input
            hidden
            multiple
            accept=".pdf, .doc, .docx, .odt"
            name="fileAttachment"
            type="file"
            onChange={handleFileAdd}
          />
        </Fab>
      ) : (
        <Button variant="contained" component="label">
          {t("Add files")}
          <input
            hidden
            multiple
            accept=".pdf, .doc, .docx, .odt"
            name="fileAttachment"
            type="file"
            onChange={handleFileAdd}
          />
        </Button>
      )}
    </Box>
    </>
  );
};

const DogAttachmentsLinksList = ({ formMethods }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [linkText, setLinkText] = useState("");
  const { control } = formMethods;

  const handleLinkChange = (e) => {
    setLinkText(e.target.value);
  };

  const handleLinkAdd = async () => {

    if (!linkText) {
      return;
    }

    // Check that the link is a valid URL.
    try {
      const url = new URL(linkText);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t(`Invalid link: ${linkText}`),
      });
      return;
    }

    if (links.find((link) => link === linkText)) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t(`Link ${value} already added.`),
      });
      return;
    }

    // Append new link to formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        links: [
          ...prevFormData.attachments.links,
          linkText,
        ],
      },
    }));

    setLinkText("");
  };

  const handleLinkDelete = async (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        links: [
          ...prevFormData.attachments.links.slice(0, index),
          ...prevFormData.attachments.links.slice(index + 1),
        ],
      },
    }));
  };


  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t("Links")}</Typography>
      </Box>
      {(! links || links.length == 0) && (
        <Box mb={2}>
          <Typography variant="body">{t("No links added yet.")}</Typography>
        </Box>
      )}
      {links?.map((link, index) => (
        <TableContainer key={index}>
          <Table>
            <TableBody>
              <TableRow>
              <TableCell>
                  <IconButton onClick={() => handleLinkDelete(index)}>
                    <FontAwesomeIcon icon={faTrash} style={iconStyle} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {link}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
      <Grid item xs={12} sm={12} mb={2}>
        <Controller
          name="linkText"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t("Link (http:// or https://)")}
              value={linkText}
              onChange={handleLinkChange}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleLinkAdd}
          sx={{ mt: 2 }}
        >
          {t("Save link")}
        </Button>
      </Grid>
    </>
  );
};

const DogAttachmentsNotesList = ({ formMethods }) => {
  const { t, i18n } = useTranslation();
  const { control } = formMethods;
  const [noteText, setNoteText] = useState("");

  const handleNoteChange = (e) => {
    setNoteText(e.target.value);
  };

  const handleNoteAdd = async () => {

    if (!noteText) {
      return;
    }

    if (notes.find((note) => note === noteText)) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t(`Note "${noteText}" already added.`),
      });
      return;
    }

    // Append new Note to formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        text: [
          ...prevFormData.attachments.text,
          noteText,
        ],
      },
    }));

    setNoteText("");
  };

  const handleNoteDelete = async (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        text: [
          ...prevFormData.attachments.text.slice(0, index),
          ...prevFormData.attachments.text.slice(index + 1),
        ],
      },
    }));
  };


  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t("Notes")}</Typography>
      </Box>
      {(! notes || notes.length == 0) && (
        <Box mb={2}>
          <Typography variant="body">{t("No notes added yet.")}</Typography>
        </Box>
      )}
      {notes?.map((note, index) => (
        <TableContainer key={index}>
          <Table>
            <TableBody>
              <TableRow>
              <TableCell>
                  <IconButton onClick={() => handleNoteDelete(index)}>
                    <FontAwesomeIcon icon={faTrash} style={iconStyle} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {note}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
      <Grid item xs={12} sm={12} mb={2}>
        <Controller
          name="noteText"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              multiline
              label={t("Note")}
              value={noteText}
              onChange={handleNoteChange}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleNoteAdd}
          sx={{ mt: 2 }}
        >
          {t("Save note")}
        </Button>
      </Grid>
    </>
  );
};

/* Main */

return (
<Grid container>
  <Grid item xs={12} sm={12}>
    <Box mb={2}>
      <Typography variant="body">{t("Attach additional documents here, such as health results or pedigree information.")}</Typography>
    </Box>
    </Grid>
    <Grid item xs={12} sm={12} mb={2}>
      <DogAttachmentsFilesList formMethods={formMethods} />
    </Grid>

    <Grid item xs={12} sm={12}>
      <DogAttachmentsLinksList formMethods={formMethods} />
    </Grid>

    <Grid item xs={12} sm={12}>
      <DogAttachmentsNotesList formMethods={formMethods} />
    </Grid>
  </Grid>
);
}