import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { displayDate } from "constants/helpers";

class LitterInfoBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      litter: this.props.litter,
    };
  }

  /**
  // Calculate dogs age in yrs and months between parents bd and litters bd.
  // If we use this again, we should subtract 2 months for gestation.
  calculateAge(input) {
    const litter = this.props.litter;
    const today = dayjs(input);
    const dateOfBirth = dayjs(litter.dateOfBirth);
    const ageInMonths = dateOfBirth.diff(today, "months");

    const years = ageInMonths / 12;
    const months = ageInMonths % 12;
    return (
      Math.floor(years) +
      " " +
      i18next.t("yrs") +
      " " +
      months +
      " " +
      i18next.t("mos")
    );
  } **/
  render() {
    const { t } = this.props;
    const litter = this.state.litter;
    const error = " ";

    const males = litter?.puppies?.reduce(
      (total, puppy) => (total += puppy.sex === "male"),
      0
    );
    const females = litter?.puppies?.reduce(
      (total, puppy) => (total += puppy.sex === "female"),
      0
    );

    /*
    const sireAgeAtBirth = litter?.sire
      ? this.calculateAge(litter?.sire?.dateOfBirth)
      : " ";
    const damAgeAtBirth = litter?.dam
      ? this.calculateAge(litter?.dam?.dateOfBirth)
      : " ";
*/

    return (
      <>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h3">{t("litterInformation")}</Typography>
        </Divider>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow hover>
                <TableCell variant="head">{t("Litter name")}</TableCell>
                <TableCell>{litter?.name || "Unnamed litter"}</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell variant="head">{t("dob")}</TableCell>
                <TableCell>
                  {" "}
                  {litter?.dateOfBirth ? displayDate(litter.dateOfBirth) : error}                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell variant="head">{t("males")}</TableCell>
                <TableCell>{males || 0}</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell variant="head">{t("females")}</TableCell>
                <TableCell>{females || 0}</TableCell>
              </TableRow>

              {/* CERTIFICATES */ }
              <TableRow>
                <TableCell variant="head">{t("certificates")}</TableCell>
                {litter.certificates?.length > 0 ? (
                  litter.certificates.map((cert, i) => (
                    <React.Fragment key={cert._id}>
                      <TableCell>
                        <Button
                          href={cert.certificate.url}
                          endIcon={<LaunchIcon />}
                          size="small"
                          sx={{ display: { xs: "none", sm: "flex" } }}
                        >
                          {cert.certificate.name}
                        </Button>
                        <IconButton
                          color="primary"
                          href={cert.certificate.url}
                          size="small"
                          sx={{ display: { xs: "flex", sm: "none" } }}
                        >
                          <LaunchIcon />
                        </IconButton>
                              </TableCell>
                          </React.Fragment>
                          ))
                        ) : (
                      <TableCell colSpan={2}>{t("noCertificates")}</TableCell>
                        )}
              </TableRow>

              {/* <TableRow>
                <TableCell variant="head">{t("siresAgeAtDob")}</TableCell>
                <TableCell>{sireAgeAtBirth || error}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">{t("damsAgeAtDob")}</TableCell>
                <TableCell>{damAgeAtBirth || error}</TableCell>
              </TableRow> */ }
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default withTranslation()(LitterInfoBox);
