import React from "react";

// MUI
import {
  Grid,
} from "@mui/material";
// Local
import { ImageUpload } from "components/Attachments";
import { dogRegistrationState } from "recoil/registrationStates";

export default function DogInformationImages({ }) {
  return (
    <Grid container spacing={2}>
      <ImageUpload category="headImage" recoilState={dogRegistrationState}/>
      <ImageUpload category="sideImage" recoilState={dogRegistrationState}/>
    </Grid>
  );
}