// React
import React from "react";

// MUI
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  Typography,
} from "@mui/material";

// Local
import { getNameByLang } from 'constants/helpers';

export function CoatRadio ({ category, kruValues, language }) {
  const items = kruValues
    .filter(item => item.category === category && !item.aliasFor)
    .map(item => {
      const aliases = kruValues
        .filter(aliasItem => aliasItem.aliasFor === item._id)
        .map(aliasItem => getNameByLang(aliasItem, language))
        .join(", ");
      
      return {
        value: item._id,
        key: getNameByLang(item, language),
        label: `${getNameByLang(item, language).charAt(0).toUpperCase() + getNameByLang(item, language).slice(1)}${aliases ? ` (${aliases})` : ''}`,
        sort: item.sort ?? 0,
      };
    })
    .sort((a, b) => a.sort - b.sort);

  return (
    <>
      {items.map(item => (
        <FormControlLabel
          key={item.key}
          value={item.value}
          control={<Radio />}
          label={<Typography variant="body">{item.label}</Typography>}
        />
      ))}
    </>
  );
};

export function CoatCheckbox ({ category, kruValues, language, field, handleInputChange }) {
    const items = kruValues
    .filter(item => item.category === category && !item.aliasFor)
    .map(item => {
      const aliases = kruValues
        .filter(aliasItem => aliasItem.aliasFor === item._id)
        .map(aliasItem => getNameByLang(aliasItem, language))
        .join(", ");
      
      return {
        value: item._id,
        key: getNameByLang(item, language),
        label: `${getNameByLang(item, language).charAt(0).toUpperCase() + getNameByLang(item, language).slice(1)}${aliases ? ` (${aliases})` : ''}`,
        sort: item.sort ?? 0,
      };
    })
    .sort((a, b) => a.sort - b.sort);

  return (
    <>
      {items.map(item => (
        <FormControlLabel
          key={item.key}
          control={
            <Checkbox
              id={item.value}
              checked={field?.value?.includes(item.value)}
              onChange={(e) => {
                const newValue = e.target.checked
                  ? [...field?.value, item.value]
                  : field.value.filter((val) => val !== item.value);
                field.onChange(newValue);
                handleInputChange({ target: { name: field.name, value: newValue } });
              }}
            />
          }
          label={<Typography variant="body">{item.label}</Typography>}
        />
      ))}
    </>
  );
};

export function BaseCoatRadio (props) {
  const { kruValues, language } = props;

  return (

    <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
    <FormLabel component="legend">
      <Typography variant="body">Reds</Typography>
    </FormLabel>
    <Box mb={2}>
      <CoatRadio
        category="coat.colors.pheomelanin"
        kruValues={kruValues}
        language={language}
      />
    </Box>
    <FormLabel component="legend">
      <Typography variant="body">Brown/Black</Typography>
    </FormLabel>
    <Box mb={2}>
      <CoatRadio
        category="coat.colors.eumelanin"
        kruValues={kruValues}
        language={language}
      />
    </Box>
    <FormLabel component="legend">
      <Typography variant="body">Dilutes</Typography>
    </FormLabel>
    <Box mb={2}>
      <CoatRadio
        category="coat.colors.dilute"
        kruValues={kruValues}
        language={language}
      />
    </Box>
    <Box mb={2}>
      <FormControlLabel
        key="unknown"
        value="coat.colors.unknown"
        control={<Radio />}
        label={<Typography variant="body">Unknown/Unsure</Typography>}
      />
    </Box>
    </FormGroup>
  );
}
