import React from "react";
import { useTranslation } from "react-i18next";

// Steps
import DogRegistrationForm from "./steps/dogRegistrationForm";
import DogSummary from "./steps/dogSummary";

// MUI
import { Stepper, Step, StepLabel, Box } from "@mui/material";

export default function DogRegistration({ activeStep, errors }) {
  const { t } = useTranslation();

  function currentStep() {
    switch (activeStep) {
      case 0:
        return <DogRegistrationForm />;
      case 1:
        return <DogSummary />;
    }
  }

  return (
    <Box sx={{ p: 3 }}>
      <Stepper
        activeStep={activeStep}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", sm: "center" },
        }}
      >
        <Step key="1">
          <StepLabel error={Object.keys(errors).length !== 0}>
            {t("registrationForm")}
          </StepLabel>
        </Step>
        <Step key="2">
          <StepLabel>{t("summary")}</StepLabel>
        </Step>
      </Stepper>
      <Box sx={{ mt: 6 }}>{currentStep()}</Box>
    </Box>
  );
}
