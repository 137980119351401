import React from "react";
import {
  Controller,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  kruValuesState,
} from "recoil/globalStates";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import {
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";

// Local
import {
  createHandleInputChange,
} from "constants/helpers";
import {
  BaseCoatRadio,
  CoatCheckbox,
  CoatRadio,
} from "components/registrationComponents/coat";

export default function TreedogInformationBreeding({ formMethods }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const kruValues = useRecoilValue(kruValuesState);
  const lightergrey = "#f0f0f0";

  const {
    control,
    formState: { errors },
  } = formMethods;

  const [formData, setFormData] = useRecoilState(treedogAdditionState);
  const handleInputChange = createHandleInputChange(setFormData);


  return (
    <>
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body">
            {t("This section is for expressed traits only. Coat color/pattern/type alleles will be determined from genetic test results.")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            <Typography variant="h5">{t("Base color")}</Typography>
          </FormLabel>
          <Controller
            name="color"
            control={control}
            defaultValue={formData.color}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
                <BaseCoatRadio kruValues={kruValues} language={language} />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>
    
      <Grid item xs={12} sm={6} md={4}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            <Typography variant="h5">{t("Markings")}</Typography>
          </FormLabel>
          <Controller
            name="whitemarkings"
            control={control}
            defaultValue={formData.whitemarkings}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <FormLabel component="legend"><Typography variant="body">White markings</Typography></FormLabel>
                  <CoatRadio
                    category="coat.markings.white"
                    kruValues={kruValues}
                    language={language}
                  />
                </FormGroup>
              </RadioGroup>
            )}
          />
    
          <Controller
            name="spots"
            control={control}
            defaultValue={formData.spots}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2, backgroundColor: lightergrey }}>
                  <FormLabel component="legend"><Typography variant="body">Spots</Typography></FormLabel>
                  <CoatRadio
                    category="coat.markings.spots"
                    kruValues={kruValues}
                    language={language}
                  />
                </FormGroup>
              </RadioGroup>
            )}
          />
    
          <Controller
            name="othermarkings"
            control={control}
            defaultValue={formData.othermarkings}
            render={({ field }) => (
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">Other</Typography></FormLabel>
                <CoatCheckbox
                  category="coat.markings.other"
                  kruValues={kruValues}
                  language={language}
                  field={field}
                  handleInputChange={handleInputChange}
                />
              </FormGroup>
            )}
          />
        </FormControl>
      </Grid>
    
      <Grid item xs={12} sm={6} md={4}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            <Typography variant="h5">{t("Modifiers")}</Typography>
          </FormLabel>
          <Controller
            name="alocusmodifiers"
            control={control}
            defaultValue={formData.alocusmodifiers}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <FormLabel component="legend"><Typography variant="body">A Locus</Typography></FormLabel>
                  <CoatRadio
                    category="coat.modifiers.alocus"
                    kruValues={kruValues}
                    language={language}
                  />
                </FormGroup>
              </RadioGroup>
            )}
          />
    
          <Controller
            name="merlemodifiers"
            control={control}
            defaultValue={formData.merlemodifiers}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
                <FormGroup sx={{ border: '1px solid black', backgroundColor: lightergrey, padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <FormLabel component="legend"><Typography variant="body">Merle</Typography></FormLabel>
                  <CoatRadio
                    category="coat.modifiers.merle"
                    kruValues={kruValues}
                    language={language}
                  />
                </FormGroup>
              </RadioGroup>
            )}
          />
    
          <Controller
            name="othermodifiers"
            control={control}
            defaultValue={formData.othermodifiers}
            render={({ field }) => (
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">Other</Typography></FormLabel>
                <CoatCheckbox
                  category="coat.modifiers.other"
                  kruValues={kruValues}
                  language={language}
                  field={field}
                  handleInputChange={handleInputChange}
                />
              </FormGroup>
            )}
          />
        </FormControl>
      </Grid>
    
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth>
          <FormLabel>
            <Typography variant="h5">{t("Coat type")}</Typography>
          </FormLabel>
          <Controller
            name="coattype"
            control={control}
            defaultValue={formData.coattype}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <CoatRadio
                    category="coat.types"
                    kruValues={kruValues}
                    language={language}
                  />
                </FormGroup>
              </RadioGroup>
            )}
          />
        </FormControl>
    
        <FormControl fullWidth>
          <FormLabel>
            <Typography variant="h5">{t("coatQualities")}</Typography>
          </FormLabel>
          <Controller
            name="coatcurliness"
            control={control}
            defaultValue={formData.coatcurliness}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <FormLabel component="legend"><Typography variant="body">Curliness</Typography></FormLabel>
                  <CoatRadio
                    category="coat.qualities.curliness"
                    kruValues={kruValues}
                    language={language}
                  />
                </FormGroup>
              </RadioGroup>
            )}
          />
          <Controller
            name="coatqualities"
            control={control}
            defaultValue={formData.coatqualities}
            render={({ field }) => (
              <FormGroup sx={{ border: '1px solid black', backgroundColor: lightergrey, padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">{t("Other coat qualities")}</Typography></FormLabel>
                <CoatCheckbox
                  category="coat.qualities.other"
                  kruValues={kruValues}
                  language={language}
                  field={field}
                  handleInputChange={handleInputChange}
                />
              </FormGroup>
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
    </>
  );
}
