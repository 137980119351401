import React, { useEffect } from "react";
import {
  Controller,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accessTokenState,
  userState,
} from "recoil/globalStates";
import { createAdminAllOrgBreedersState } from "recoil/adminStates";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import {
  Autocomplete,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Local
import { checkIfMicrochipExists } from "api/privateRoutes";
import {
  createHandleInputChange,
} from "constants/helpers";
import { countries } from "constants/countries";

export default function TreedogInformationBasic() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { organizationPrefix } = useParams();
  const user = useRecoilValue(userState);
  const accessToken = useRecoilValue(accessTokenState);
  const formMethods = useFormContext();
  const {
    clearErrors,
    control,
    formState: { errors },
    setError
  } = formMethods;

  const [formData, setFormData] = useRecoilState(treedogAdditionState);
  const handleInputChange = createHandleInputChange(setFormData);

  const adminAllBreedersOrgState = createAdminAllOrgBreedersState(organizationPrefix);
  const breeders = useRecoilValue(adminAllBreedersOrgState);

  // Set country of origin default to user's country
  let defaultCountry = countries.find((c) => c.code === user.country);
  useEffect(() => {
    if (!formData.countryOfOrigin) {
      setFormData({
        ...formData,
        ["countryOfOrigin"]: defaultCountry,
      });
  }
  }, []);

  const checkMicrochipNumber = (e) => {
    if (e.target.value && e.target.value !== "") {
      checkIfMicrochipExists(e.target.value, { accessToken }).then(
        (response) => {
          if (response === true) {
            setError(e.target.name, {
              type: "custom",
              message: t("checkMicrochipNumber"),
            });
          } else {
            clearErrors(e.target.name);
          }
        }
      );
      clearErrors(e.target.name);
    }
  };  

  const validateDateOfBirth = (e, field) => {
    let parsedDate = dayjs(e.target.value);
    if (!dayjs(parsedDate).isValid()) {
      setError("dateOfBirth", {
        type: "custom",
        message: t("Invalid date") + " (mm/dd/yyyy)",
      });
    } else if (dayjs(parsedDate).isAfter(dayjs())) {
      setError(name, {
        type: "custom",
        message: t("Date cannot be in the future"),
      });
    } else {
      clearErrors("dateOfBirth");
    }
  };


  return (
    <Grid item xs={12} sx={{ mb: 6 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Controller
              name="name"
              control={control}
              defaultValue={formData.name}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  value={formData.name}
                  label={t("name")}
                  error={errors?.name && true}
                  helperText={errors && errors?.name?.message}
                  onChange={(e) => handleInputChange(e, field)}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name="microchipNumber"
              control={control}
              defaultValue={formData.microchipNumber || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  value={formData.microchipNumber}
                  label={t("microchipNumber")}
                  error={errors?.microchipNumber && true}
                  helperText={errors?.microchipNumber?.message}
                  onChange={(e) => handleInputChange(e, field)}
                  onBlur={(e) => {
                    field.onBlur(e);
                    checkMicrochipNumber(e, accessToken);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={3}>
          <FormLabel>{t("sex")}</FormLabel>
          <Controller
            name="sex"
            control={control}
            defaultValue={formData.sex || "male"}
            rules={{
              required: t("required"),
            }}
            render={({ field }) => (
              <RadioGroup
                {...field}
                row
                value={formData.sex}
                onChange={(e) => handleInputChange(e, field)}
                >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label={<Typography variant="body">{t("male")}</Typography>}
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label={<Typography variant="body">{t("female")}</Typography>}
                />
              </RadioGroup>
            )}
          />
        </Grid>

        <Grid item xs={5}>
          <Controller
            name="callname"
            control={control}
            defaultValue={formData.callname || ""}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                value={formData.callname}
                label={t("Call name")}
                onChange={(e) => handleInputChange(e, field)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="breeder"
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                fullWidth
                options={breeders}
                value={formData.breeder || null}
                getOptionLabel={(option) => `${option.name} (${option.email})`}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name} {" ("}
                    {option.email}
                    {") "}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  option._id == value._id
                }
                renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("Breeder")}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                onChange={(e, data) => {
                  onChange(data);
                  setFormData({
                    ...formData,
                    ["breeder"]: data
                  });
                }}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={formData.breeder}
            control={control}
          />
        </Grid>


        <Grid item xs={12} sm={3} sx={{ mb: 2 }}>
          <Controller
            name="dateOfBirth"
            control={control}
            defaultValue={formData.dateOfBirth}
            render={({ field: { ref, onChange, name, value, ...field }, fieldState: { error } }) => (
              <DatePicker
                {...field}
                inputRef={ref}
                label={t("Date of birth")}
                disableFuture
                value={value}
                onChange={(date) => {
                  const formattedDate = dayjs(date).format('MM/DD/YYYY');
                  handleInputChange(
                    {
                      target: {
                        name: name,
                        value: formattedDate,
                      }
                    },
                    field
                  );
                  onChange(formattedDate);
                }}
                error={errors?.dateOfBirth && true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    value={formData.dateOfBirth}
                    name={name}
                    error={errors?.dateOfBirth && true}
                    helperText={errors && errors?.dateOfBirth?.message}
                    onBlur={(e) => {
                      handleInputChange(e, field);
                      validateDateOfBirth(e, field);
                    }} 
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
            name="countryOfOrigin"
            control={control}
            defaultValue={
              formData.countryOfOrigin || defaultCountry
            }
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                fullWidth
                freeSolo
                label={t("Country of origin")}
                options={countries}
                getOptionLabel={(option) =>
                  option[language] ? option[language] : ""
                }
                value={formData.countryOfOrigin || defaultCountry}
                renderOption={(props, option) => (
                  <li {...props} key={option.code}>
                    {option[language]} {option.code}
                  </li>
                )}
                placeholder={t("Country of origin")}
                isOptionEqualToValue={(option, value) =>
                  option.code == value.code
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Country of origin")}
                    value={formData.countryOfOrigin}
                    />
                )}
                onChange={(e, data) => {
                  onChange(data);
                  setFormData({
                    ...formData,
                    ["countryOfOrigin"]: data
                  });
                }}
                {...props}
              />
            )}
          />
        </Grid>

        </Grid>
      </Grid>
  );
}