import React, { useEffect } from "react";
import {
  Controller,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  kruValuesState,
} from "recoil/globalStates";
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

// Local
import {
  getBreeds,
  getMixes,
  getTypes
} from "constants/helpers";

export default function DogInformationBreeding({ formMethods }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const {
    control,
    clearErrors,
    setError,
    formState: { errors },
  } = formMethods;

  const [formData, setFormData] = useRecoilState(dogRegistrationState);

  const {
    declaredBreed,
    declaredMix,
    declaredType1,
    declaredType2,
  } = formData;


/* Lots of declared type stuff */
// Map dog.declaredTypes from kruValue ids to kruValue objects
useEffect(() => {
  const invalid = validateDeclaredTypes();
}, [declaredBreed, declaredMix, declaredType1, declaredType2]);

const validateDeclaredTypes = () => {
  const declared = [declaredBreed, declaredMix, declaredType1, declaredType2].filter(Boolean);
  const clearAllErrors = () => {
    clearErrors("declaredBreed");
    clearErrors("declaredMix");
    clearErrors("declaredType");
  };

  if (0 <= declared.length && declared.length <= 1) {
    clearAllErrors();
    return;
  }

  if (declared.length == 2 && [declaredType1, declaredType2].filter(Boolean).length == 2) {
     clearAllErrors();
    if (declaredType1._id === declaredType2._id) {
      setError("declaredType", { message: t("The two types must not be the same.") });
      return;
    }
    return;
  }

  const msg = t("Breeding description must be a single breed OR a single mix OR 1-2 types.");
  declaredBreed ?
    setError("declaredBreed", { message: msg }) :
    clearErrors("declaredBreed");
  
  declaredMix ?
    setError("declaredMix", { message: msg }) :
    clearErrors("declaredMix");

  (declaredType1 || declaredType2) ?
    setError("declaredType", { message: msg }) :
    clearErrors("declaredType");
  };


  const kruValues = useRecoilValue(kruValuesState);
  const breeds = getBreeds(kruValues, language);
  const mixes = getMixes(kruValues, language);
  const types = getTypes(kruValues, language);
  const kruAncestry = kruValues.filter((value) => value.category === "ancestry");


  return (
    <>
    <Grid item xs={12} sm={6}>
    <Box mb={2}>
    <Typography variant="body">{t("Select breed (for example, \"Labrador Retriever\" or \"Goldendoodle\")")}:</Typography>
    </Box>
    <Controller
      name="declaredBreed"
      control={control}
      defaultValue={formData.declaredBreed || ""}
      render={({ field: { onChange, ...props } }) => (
        <Autocomplete
          fullWidth
          freeSolo
          label={t("(Breed")}
          options={breeds}
          getOptionLabel={(option) =>
            option[language] ? option[language].name : ""
          }
          value={formData.declaredBreed || ""}
          onChange={(e, data) => {
            onChange(data);
            setFormData({
              ...formData,
              ["declaredBreed"]: data
            });
          }}
          isOptionEqualToValue={(option, value) =>
            option._id === value._id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Breed")}
              error={!!errors.declaredBreed}
              helperText={errors.declaredBreed?.message}
            />
          )}
          {...props}
        />
      )}
    />
    </Grid>

    <Grid item xs={12} sm={6}>
    <Box mb={2}>
      <Typography variant="body">{t("OR, select mix (for example, \"Labradoodle\" or \"Pomsky\"):")}</Typography>
    </Box>
    <Controller
      name="declaredMix"
      control={control}
      defaultValue={formData.declaredMix || ""}
      render={({ field: { onChange, ...props } }) => (
        <Autocomplete
          fullWidth
          freeSolo
          label={t("Mix")}
          value={formData.declaredMix || ""}
          options={mixes}
          getOptionLabel={(option) =>
            option[language] ? option[language].name : ""
          }
          onChange={(e, data) => {
            onChange(data);
            setFormData({
              ...formData,
              ["declaredMix"]: data
            });
          }}
          isOptionEqualToValue={(option, value) =>
            option._id === value._id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Mix")}
              error={!!errors.declaredMix}
              helperText={errors.declaredMix?.message}
            />
          )}
          {...props}
        />
      )}
    />
    </Grid>

    <Grid item xs={12} sm={6}>
    <Box mb={2}>
      <Typography variant="body">
        {t("OR, for mixes without a widely-recognized name, select up to two types that best describe the breed content:")}
        </Typography>
    </Box>
    <Grid item xs={12}>
    <Box mb={2}>
    <Controller
      key="declaredType1"
      name="declaredType1"
      control={control}
      defaultValue={formData.declaredType1 || ""}
      render={({ field: { onChange, ...props } }) => (
        <Autocomplete
          fullWidth
          freeSolo
          label="Type"
          value={formData.declaredType1 || ""}
          options={types}
          getOptionLabel={(option) =>
            option[language] ? option[language].name : ""
          }
          onChange={(e, data) => {
            onChange(data);
            setFormData({
              ...formData,
              ["declaredType1"]: data
            });
          }}
          isOptionEqualToValue={(option, value) =>
            option._id === value._id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Type")}
              error={!!errors.declaredType}
              helperText={errors.declaredType?.message}
            />
          )}
          {...props}
        />
      )}
    />
    </Box>
    <Box mb={2}>
    <Controller
      key="declaredType2"
      name="declaredType2"
      control={control}
      defaultValue={formData.declaredType2 || ""}
      render={({ field: { onChange, ...props } }) => (
        <Autocomplete
          fullWidth
          freeSolo
          value={formData.declaredType2 || ""}
          label="Type"
          options={types}
          getOptionLabel={(option) =>
            option[language] ? option[language].name : ""
          }
          onChange={(e, data) => {
            onChange(data);
            setFormData({
              ...formData,
              ["declaredType2"]: data
            });
          }}
          isOptionEqualToValue={(option, value) =>
            option._id === value._id
          }
          renderInput={(params) => (
            <TextField {...params} label={t("Type")} />
          )}
          {...props}
        />
      )}
    />
    </Box>
    </Grid>
    </Grid>
    </>
  );
}
