import React from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import { Box } from "@mui/material";

// Local
import TreedogAdditionForm from "./treedogAdditionForm";

export default function TreedogAddition({ errors }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useRecoilState(treedogAdditionState);

  return (
    <Box sx={{ mt: 6 }}><TreedogAdditionForm /></Box>
  );
}
