import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import {
  useRecoilValue,
} from "recoil";
import {
  kruValuesState,
} from "recoil/globalStates";

// MUI
import {
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { BaseCoatRadio, CoatCheckbox, CoatRadio } from "components/registrationComponents/coat";

function AdminDogAppearance({ dog, formMethods }) {
  const [initialDog, setInitialDog] = useState(dog);
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const kruValues = useRecoilValue(kruValuesState);
  const { control, setValue, formState: { errors } } = formMethods;
  const lightergrey = "#f0f0f0";

  const whitemarkings = initialDog.coat?.markings?.find(marking =>
    marking.startsWith("coat.markings.white")
  ) || [];
  
  const spots = initialDog.coat?.markings?.find(marking =>
    marking.startsWith("coat.markings.spots")
  ) || [];
  
  const othermarkings = initialDog.coat?.markings?.filter(marking =>
    marking.startsWith("coat.markings.other")
  ) || [];
  
  const alocusmodifiers = initialDog.coat?.modifiers?.find(marking =>
    marking.startsWith("coat.modifiers.alocus")
  ) || [];
  
  const merlemodifiers = initialDog.coat?.modifiers?.find(marking =>
    marking.startsWith("coat.modifiers.merle")
  ) || [];
  
  const othermodifiers = initialDog.coat?.modifiers?.filter(marking =>
    marking.startsWith("coat.modifiers.other")
  ) || [];
  
  const coatcurliness = initialDog.coat?.qualities?.find(marking =>
    marking.startsWith("coat.qualities.curliness")
  ) || [];
  
  const coatqualities = initialDog.coat?.qualities?.filter(marking =>
    marking.startsWith("coat.qualities.other")
  ) || [];
  return (
    <>

<Grid item xs={12} sm={12}>
  <Divider textAlign="left" sx={{ my: 2 }}>
    <Typography variant="h5">{t("Coat")}</Typography>
  </Divider>
</Grid>

<Grid item xs={6}>
<FormControl component="fieldset" fullWidth>
  <FormLabel component="legend">
    <Typography variant="h5">{t("Base color")}</Typography>
  </FormLabel>
  <Controller
    name="color"
    control={control}
    defaultValue={initialDog?.coat?.color}
    render={({ field }) => (
    <RadioGroup {...field}>
      <BaseCoatRadio kruValues={kruValues} language={language} />
    </RadioGroup>
    )}
  />
  </FormControl>
  </Grid>

<Grid item xs={6}>
  <FormControl component="fieldset" fullWidth>
    <FormLabel component="legend">
      <Typography variant="h5">{t("Markings")}</Typography>
    </FormLabel>
    <Controller
      name="whitemarkings"
      control={control}
      defaultValue={whitemarkings}
      render={({ field }) => (
        <RadioGroup {...field}>
          <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
            <FormLabel component="legend"><Typography variant="body">White markings</Typography></FormLabel>
            <CoatRadio
              category="coat.markings.white"
              kruValues={kruValues}
              language={language}
            />
          </FormGroup>
      </RadioGroup>
    )}
  />

<Controller
    name="spots"
    control={control}
    defaultValue={spots}
    render={({ field }) => (
      <RadioGroup {...field}>
      <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2, backgroundColor: lightergrey }}>
        <FormLabel component="legend"><Typography variant="body">Spots</Typography></FormLabel>
        <CoatRadio
          category="coat.markings.spots"
          kruValues={kruValues}
          language={language}
        />
        </FormGroup>
      </RadioGroup>
    )}
  />

<Controller
  name="othermarkings"
  control={control}
  defaultValue={othermarkings}
  render={({ field: { onChange, value, ...field } }) => (
    <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
      <FormLabel component="legend"><Typography variant="body">Other</Typography></FormLabel>
      <CoatCheckbox category="coat.markings.other" kruValues={kruValues} language={language} />
    </FormGroup>
  )}
/>
  </FormControl>
</Grid>

<Grid item xs={6}>
  <FormControl component="fieldset" fullWidth>
    <FormLabel component="legend">
      <Typography variant="h5">{t("Modifiers")}</Typography>
    </FormLabel>
    <Controller
      name="alocusmodifiers"
      defaultValue={alocusmodifiers}
      control={control}
      render={({ field }) => (
      <RadioGroup {...field}>
      <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
        <FormLabel component="legend"><Typography variant="body">A Locus</Typography></FormLabel>
        <CoatRadio category="coat.modifiers.alocus" kruValues={kruValues} language={language} />
        </FormGroup>
        </RadioGroup>
      )}
      />

    <Controller
      name="merlemodifiers"
      control={control}
      defaultValue={merlemodifiers}
      render={({ field }) => (
      <RadioGroup {...field}>
        <FormGroup sx={{ border: '1px solid black', backgroundColor: lightergrey, padding: 2, borderRadius: 1, marginBottom: 2 }}>
          <FormLabel component="legend"><Typography variant="body">Merle</Typography></FormLabel>
          <CoatRadio category="coat.modifiers.merle" kruValues={kruValues} language={language} />
        </FormGroup>
      </RadioGroup>
      )}
    />

    <Controller
      name="othermodifiers"
      control={control}
      defaultValue={othermodifiers}
      render={({ field: { onChange, value, ...field } }) => (
        <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
          <FormLabel component="legend"><Typography variant="body">Other</Typography></FormLabel>
          <CoatCheckbox category="coat.modifiers.other" kruValues={kruValues} language={language} />
        </FormGroup>
      )}
    />
</FormControl>
</Grid>

<Grid item xs={6}>
  <FormControl fullWidth>
    <FormLabel>
      <Typography variant="h5">{t("Coat type")}</Typography>
    </FormLabel>
    <Controller
      name="coattype"
      control={control}
      defaultValue={initialDog?.coat?.type}
      render={({ field }) => (
        <RadioGroup {...field}>
          <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
            <CoatRadio category="coat.types" kruValues={kruValues} language={language} />
          </FormGroup>
        </RadioGroup>
        )}
    />
  </FormControl>

  <FormControl fullWidth>
    <FormLabel>
      <Typography variant="h5">{t("coatQualities")}</Typography>
    </FormLabel>
    <Controller
      name="coatcurliness"
      control={control}
      defaultValue={coatcurliness}
      render={({ field }) => (
        <RadioGroup {...field}>
          <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
          <FormLabel component="legend"><Typography variant="body">Curliness</Typography></FormLabel>
            <CoatRadio category="coat.qualities.curliness" kruValues={kruValues} language={language} />
          </FormGroup>
          </RadioGroup>
      )}
    />
    <Controller
      name="coatqualities"
      control={control}
      defaultValue={coatqualities}
      render={({ field: { onChange, value, ...field } }) => (
        <FormGroup sx={{ border: '1px solid black', backgroundColor: lightergrey, padding: 2, borderRadius: 1, marginBottom: 2 }}>
            <FormLabel component="legend"><Typography variant="body">{t("Other coat qualities")}</Typography></FormLabel>
            <CoatCheckbox category="coat.qualities.other" kruValues={kruValues} language={language} />
        </FormGroup>
      )}
    />
  </FormControl>
</Grid>

<Grid item xs={12}>
<Divider textAlign="left" sx={{ my: 2 }}>
  <Typography variant="h5">{t("Size")}</Typography>
</Divider>
</Grid>

<Grid container spacing={2}>
    <Grid item xs={12} sm={3}>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="heightIn"
                control={control}
                defaultValue={initialDog?.height > 0 ? Math.round(initialDog?.height / 2.54) : null}
                rules={{
                  min: {
                    value: 1,
                    message: t("Height must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Height - in")}
                    error={errors?.heightIn && true}
                    helperText={errors && errors?.heightIn?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('height', Math.round(e.target.value * 2.54));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="height"
                control={control}
                defaultValue={initialDog?.height}
                rules={{
                  min: {
                    value: 1,
                    message: t("Height must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Height - cm")}
                    error={errors?.height && true}
                    helperText={errors && errors?.height?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('heightIn', e.target.value === null ? null : Math.round(e.target.value / 2.54));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    <Grid item xs={12} sm={3}>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="weightLb"
                control={control}
                defaultValue={initialDog?.weight > 0 ? Math.round(initialDog?.weight / 0.453592) : null}
                rules={{
                  min: {
                    value: 1,
                    message: t("Weight must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Weight - lb")}
                    error={errors?.weightLb && true}
                    helperText={errors && errors?.weightLb?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('weight', e.target.value === null ? null : Math.round(e.target.value * 0.453592));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="weight"
                control={control}
                defaultValue={initialDog?.weight}
                rules={{
                  min: {
                    value: 1,
                    message: t("Weight must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Weight - kg")}
                    error={errors?.weight && true}
                    helperText={errors && errors?.weight?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('weightLb', Math.round(e.target.value / 0.453592));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
</>  );
}

export default AdminDogAppearance;
