import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Recoil
import { useRecoilValue, useResetRecoilState } from "recoil";
import { kruValuesState } from "recoil/globalStates";
import { treedogByIdState } from "recoil/publicStates";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { CheckCircleOutlined } from "@mui/icons-material";

// Local
import { countries } from "constants/countries";
import { displayDate, displayDeclaredTypes, getKruValueLabel } from "constants/helpers";

export default function TreedogAdditionSuccess() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const language = i18n.language;
  const dog = useRecoilValue(treedogByIdState(id));
  const kruValues = useRecoilValue(kruValuesState);
  const resetFormData = useResetRecoilState(treedogAdditionState);
  resetFormData();

  return (
    <>
      <Paper sx={{ p: 4 }}>
        <Grid container alignItems="center" spacing={6}>
          <Grid item xs={12} sm={9}>
            <Typography variant="h2" sx={{ mb: 2 }}>
              {t("Tree dog addition successful")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display="flex" justifyContent="center">
              <CheckCircleOutlined
                color="primary"
                sx={{
                  fontSize: { xs: "80px", sm: "100px" },
                  textAlign: "center",
                }}
              />
            </Box>
          </Grid>
          {dog && (
            <Grid item xs={12}>
              <Typography variant="h3">{t("summary")}</Typography>
              <TableContainer>
                <Table sx={{ minWidth: 200 }}>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">{t("dogsName")}</TableCell>
                      <TableCell>{dog.name || "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">
                        {t("microchipNumber")}
                      </TableCell>
                      <TableCell>{dog.microchipNumber || "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("dob")}</TableCell>
                      <TableCell>
                        {displayDate(dog.dateOfBirth) || "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("sex")}</TableCell>
                      <TableCell>{t(dog.sex) || ""}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">
                        {t("countryOfOrigin")}
                      </TableCell>
                      <TableCell>
                        {dog.countryOfOrigin &&
                          countries.find((c) => c.code === dog.countryOfOrigin)[
                          language
                        ] || ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Breed or mix")}</TableCell>
                      <TableCell>
                        {displayDeclaredTypes(dog, kruValues, language)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Base color")}</TableCell>
                      <TableCell>{dog.coat.color ? getKruValueLabel(dog.coat.color, language) : "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Modifiers")}</TableCell>
                      <TableCell>
                        {dog.coat.modifiers?.length > 0 ? dog.coat.modifiers.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (dog.coat.modifiers.length - 1 === i ? "" : ", ")
                          );
                         }) : "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Markings")}</TableCell>
                      <TableCell>
                      {dog.coat.markings?.length > 0 ? dog.coat.markings.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (dog.coat.markings.length - 1 === i ? "" : ", ")
                          );
                         }) : "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Coat type")}</TableCell>
                      <TableCell>{dog.coat.type ? getKruValueLabel(dog.coat.type, language) : "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Coat qualities")}</TableCell>
                      <TableCell>
                      {dog.coat.qualities?.length > 0 ? dog.coat.qualities.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (dog.coat.qualities.length - 1 === i ? "" : ", ")
                          );
                         }) : "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("height")}</TableCell>
                      <TableCell>
                        {dog.height ? `${dog.height} cm` : "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("weight")}</TableCell>
                      <TableCell>
                      {dog.weight ? `${dog.weight} kg` : "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
}
