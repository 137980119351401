import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getKruValueLabel } from "constants/helpers";

// Icons
import LaunchIcon from "@mui/icons-material/Launch";
// MUI
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { countries } from "constants/countries";
import { displayDate } from "constants/helpers";

export default function BasicInformation({ dog, organization }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const error = t("noData");

  const [country, setCountry] = useState(
    dog.countryOfOrigin
      ? countries.find((c) => c.code === dog.countryOfOrigin.toUpperCase())[
          language
        ]
      : null
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow hover>
              <TableCell variant="head">{t("name")}</TableCell>
              <TableCell>{dog.name || error}</TableCell>
              <TableCell variant="head">{t("Call name")}</TableCell>
              <TableCell>{dog.callname || "-"}</TableCell>
            </TableRow>
            <TableRow hover>
            <TableCell variant="head">{t("Base coat color")}</TableCell>
              <TableCell>{dog.coat.color ? getKruValueLabel(dog.coat.color, language) : error}</TableCell>
              <TableCell variant="head">{t("Coat color/pattern modifiers")}</TableCell>
              <TableCell>{dog.coat.modifiers?.length > 0 ? dog.coat.modifiers.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (dog.coat.modifiers.length - 1 === i ? "" : ", ")
                          );
                         }) : error}
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("Markings")}</TableCell>
              <TableCell>{dog.coat.markings?.length > 0 ? dog.coat.markings.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (dog.coat.markings.length - 1 === i ? "" : ", ")
                          );
                         }) : error}
              </TableCell>
              <TableCell variant="head">{t("Coat type")}</TableCell>
              <TableCell>{dog.coat.type ? getKruValueLabel(dog.coat.type, language) : "-"}</TableCell>
            </TableRow>

            <TableRow hover>
            <TableCell variant="head">{t("Coat qualities")}</TableCell>
              <TableCell>{dog.coat.qualities?.length > 0 ? dog.coat.qualities.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (dog.coat.qualities.length - 1 === i ? "" : ", ")
                          );
                         }) : error}
              </TableCell>
              <TableCell variant="head">{t("height")}</TableCell>
              <TableCell>{dog.height ? dog.height + " cm" : error}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("sex")}</TableCell>
              <TableCell>{t(dog.sex) || error}</TableCell>
              <TableCell variant="head">{t("weight")}</TableCell>
              <TableCell>{dog.weight ? dog.weight + " kg" : error}</TableCell>
            </TableRow>

            <TableRow hover>
            <TableCell variant="head">{t("dob")}</TableCell>
              <TableCell>
                {displayDate(dog?.dateOfBirth) || error}
              </TableCell>
            <TableCell variant="head">{t("countryOfOrigin")}</TableCell>
              <TableCell>{country || error}</TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell variant="head">{t("breeder")}</TableCell>
              <TableCell colSpan={2}>
                {dog?.breeder?.name}
                {/* {breederBadge} */}
              </TableCell>
              <TableCell>
                {dog?.breeder ? (
                  <Button
                    href={`/${organization.prefix}/breeder/${dog.breeder.breederId}`}
                    endIcon={<LaunchIcon />}
                    size="small"
                  >
                    {t("breedersProfile")}
                  </Button>
                ) : (
                  " "
                )}
              </TableCell>
              </TableRow>

              <TableRow hover>
                <TableCell variant="head">{t("Owner(s)")}</TableCell>
                <TableCell colSpan={2}>
                  {dog?.owners?.map(owner => owner.name ? owner.name : `Not disclosed (${owner.breederId})`).join(', ')}
                </TableCell>
              </TableRow>  

            {dog?.isDeceased && (
              <>
                <TableRow hover>
                  <TableCell variant="head">{t("dateOfDeath")}</TableCell>
                  <TableCell>
                    {dog?.dateOfDeath ? displayDate(dog?.dateOfDeath) : ""}
                  </TableCell>
                  <TableCell variant="head">{t("causeOfDeath")}</TableCell>
                  <TableCell>{dog?.causeOfDeath}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
