import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Recoil
import {
  useRecoilValue,
} from "recoil";
import {
  kruValuesState,
} from "recoil/globalStates";

import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import PhotoModal from "./PhotoModal";
import {
  displayDate,
  displayDeclaredTypes,
  getLinkDisplay,
  getOrgAndMore,
} from "constants/helpers";
import kru from "@niitsolutions/kru";

// import { getBadge, getKruValueLabel } from "constants/helpers";

export default function OverviewCard({ dog, organization }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const orgs = dog.organizations.map(o => o.organization);
  const kruValues = useRecoilValue(kruValuesState);

  // const breederBadge = getBadge(dog?.breeder);

  const orgWithReg = getOrgAndMore(dog, organization);
  const regClass = orgWithReg?.regClass;
  let regNumber = null;
  if (regClass === "reg.classes.full" || regClass === "reg.classes.limited") {
    regNumber = getLinkDisplay(dog, organization).display;
  }

  return (
    <Grid
      container
      spacing={0}
      direction={{ xs: "column", sm: "row" }}
      alignContent="center"
      alignItems="center"
      sx={{ p: { sm: 2, md: 0 } }}
    >
      <Grid xs={6} md={3}>
        <PhotoModal dog={dog} />
      </Grid>
      <Grid xs={12} md={9}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <List disablePadding>
            <ListItem
              secondaryAction={
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, md: 2 }}
                  sx={{ display: { xs: "none" } }}
                >
                  <Chip size="small" variant="outlined" label={t(dog?.sex)} />
                  <Chip
                    size="small"
                    variant="outlined"
                    label={`${"DOB"} ${displayDate(dog?.dateOfBirth)}`}
                  />
                </Stack>
              }
            >
              <ListItemText
                sx={{ pl: { xs: 0, md: 2 } }}
                primary={<Typography variant="h2">{dog?.name} </Typography>}
                secondary={
                  <>
                    {regNumber ? `${regNumber}, ` : ''}
                    {dog?.microchipNumber ? `${t("Microchip no")}: ${dog.microchipNumber}` : ''} <br />
                    {displayDeclaredTypes(dog, kruValues, language)} <br />
                    {dog?.isDeceased ? (
                      <>
                        <br />
                        <Chip
                          size="small"
                          color="primary"
                          variant="outlined"
                          label={`${t("deceased")} ${dayjs(
                            dog?.dateOfDeath
                          ).format("MM.DD.YYYY")}, ${t(
                            "causeOfDeath"
                          ).toLowerCase()}: ${dog?.causeOfDeath}`}
                          sx={{ mt: 1 }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                }
              />
            </ListItem>
	    <>
	    { /*
            <Divider variant="middle" />
            <ListItem>
              <ListItemText
                sx={{ pl: { xs: 0, md: 2 } }}
                primary={
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, md: 4 }}
                  >
                    <Typography variant="body">{t("Registered")} </Typography>
                    <Chip
                      size="small"
                      sx={{ ml: 1 }}
                      label={
                        dayjs(dog?.createdAt).format("MM.DD.YYYY") ||
                        t("noData")
                      }
                    />

                    <Typography variant="body">{t("registry")} </Typography>
                    <Chip
                      size="small"
                      sx={{ ml: 1 }}
                      label={orgs ? orgs.map((v) => v.name).join(", ") : "-"}
                    />
                    <Typography variant="body">
                      {t("registrationType")}{" "}
                    </Typography>
                    <Chip
                      size="small"
                      sx={{ ml: 1 }}
                      label={
                        getKruValueLabel(dog?.regType, lang) || t("noData")
                      }
                    />
                  </Stack>
                }
              />
              </ListItem> */ } </>
            <Divider variant="middle" />
            <ListItem>
              <ListItemText
                sx={{ pl: { xs: 0, md: 2 } }}
                primary={
                  <>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, md: 4 }}
                    >
                      {/*
                                    {dog?.health && (
                    <>
                      <Typography variant="body">
                        {t("healthTestResults")}
                        <Chip
                          size="small"
                          sx={{ ml: 1 }}
                          label={dog?.health?.length || "0"}
                        />
                      </Typography>
                    </>
                  )}
                                            
                      {dog?.genetics && (
                        <>
                          {console.log("dog.genetics:", dog.genetics)}
                          {console.log("Type of dog.genetics:", typeof dog.genetics)}
                          <Typography variant="body">
                            {t("geneticTestResults")}
                            <Chip
                              size="small"
                              sx={{ ml: 1 }}
                            />
                          </Typography>
                        </>
                      )}
                      {dog?.competition && (
                        <Typography variant="body">
                          {t("competitionResults")}
                          <Chip
                            size="small"
                            sx={{ ml: 1 }}
                            label={dog?.competition?.length || "0"}
                          />
                        </Typography>
                      ) : null}
                       */}
                    </Stack>
                  </>
                }
              />
            </ListItem>
      <> { /*
      <Divider variant="middle" />
            <ListItem
              secondaryAction={
                dog?.breeder ? (
                  <>
                    <Button
                      href={`/breeder/${dog?.breeder?._id}`}
                      endIcon={<LaunchIcon />}
                      size="small"
                      sx={{ display: { xs: "none", sm: "flex" } }}
                    >
                      {t("breedersProfile")}
                    </Button>
                    <IconButton
                      color="primary"
                      href={`/breeder/${dog?.breeder?._id}`}
                      size="small"
                      sx={{ display: { xs: "flex", sm: "none" } }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </>
                ) : null
              }
            >
              <ListItemText
                sx={{ pl: { xs: 0, md: 2 } }}
                primary={
                  <>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 2, md: 4 }}
                    >
                      <Typography variant="body">{t("breeder")}</Typography>{" "}
                      <Typography variant="body">
                        {breeder}
                        {breederBadge}
                      </Typography>
                    </Stack>
                  </>
                }
              />
            </ListItem> */ } </>
          </List>
        </Box>
      </Grid>
    </Grid>
  );
}
