import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

// Components
import { displayDate, getVerification } from "constants/helpers";

export default function PendingLittersList({ pendingLitters }) {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  return (
    pendingLitters && (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h3">
            {t("litterRegistrations")}
            <Chip
              color="primary"
              size="small"
              label={pendingLitters.length}
              sx={{ ml: 2 }}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {pendingLitters.length > 0 ? (
            <>
              {lessThanSmall ? (
                pendingLitters.map((litter, i) => {
                  return (
                    <Card key={i} variant="outlined" sx={{ mb: 2 }}>
                      <CardContent>
                        <Typography variant="caption">{t("dob")}</Typography>
                        <Typography variant="h5">
                          {displayDate(litter.dateOfBirth)}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="caption">{t("sire")}</Typography>
                        <br />
                        <Typography variant="h5">
                          {litter?.sire?.name || t("noData")}{" "}
                          {litter?.sire?.regNumber}
                        </Typography>
                        {litter?.verifications?.length > 0 && (
                          <>
                            <Typography
                              variant="body"
                              color={
                                getVerification(
                                  litter.verifications,
                                  "verifyLitterSire"
                                )
                                  ? "success.main"
                                  : "warning.main"
                              }
                            >
                              {getVerification(
                                litter.verifications,
                                "verifyLitterSire"
                              )
                                ? t("verified")
                                : t("waitingForVerification")}
                            </Typography>
                            <br />
                            <br />
                          </>
                        )}

                        <Typography variant="caption">{t("dam")}</Typography>
                        <br />
                        <Typography variant="h5">
                          {litter?.dam?.name || t("noData")}{" "}
                          {litter?.dam?.regNumber}
                        </Typography>
                        {litter?.verifications?.length > 0 && (
                          <Typography
                            variant="body"
                            color={
                              getVerification(
                                litter.verifications,
                                "verifyLitterDam"
                              )
                                ? "success.main"
                                : "warning.main"
                            }
                          >
                            {getVerification(
                              litter.verifications,
                              "verifyLitterDam"
                            )
                              ? t("verified")
                              : t("waitingForVerification")}
                          </Typography>
                        )}

                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body">
                          {`${litter.puppies?.length} ${t(
                            "puppiesCount"
                          ).toLowerCase()}`}
                        </Typography>
                      </CardContent>
                      <Divider />
                      <CardActions sx={{ justifyContent: "center" }}>
                        <Typography variant="body">
                          Status: {t(litter.registrationStatus)}
                        </Typography>
                      </CardActions>
                    </Card>
                  );
                })
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("dob")}</TableCell>
                        <TableCell>{t("sire")}</TableCell>
                        <TableCell>{t("dam")}</TableCell>
                        <TableCell>{t("puppies")}</TableCell>
                        <TableCell>{t("status")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pendingLitters.map((litter, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>
                              {displayDate(litter.dateOfBirth)}
                            </TableCell>
                            <TableCell>
                              {litter?.sire?.name || t("noData")}{" "}
                              {litter?.sire?.regNumber}
                              <br />
                              {litter?.verifications?.length > 0 && (
                                <Typography
                                  variant="body"
                                  color={
                                    getVerification(
                                      litter.verifications,
                                      "verifyLitterSire"
                                    )
                                      ? "success.main"
                                      : "warning.main"
                                  }
                                >
                                  {getVerification(
                                    litter.verifications,
                                    "verifyLitterSire"
                                  )
                                    ? t("verified")
                                    : t("waitingForVerification")}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>
                              {litter?.dam?.name || t("noData")}{" "}
                              {litter?.dam?.regNumber}
                              <br />
                              {litter?.verifications?.length > 0 && (
                                <Typography
                                  variant="body"
                                  color={
                                    getVerification(
                                      litter.verifications,
                                      "verifyLitterDam"
                                    )
                                      ? "success.main"
                                      : "warning.main"
                                  }
                                >
                                  {getVerification(
                                    litter.verifications,
                                    "verifyLitterDam"
                                  )
                                    ? t("verified")
                                    : t("waitingForVerification")}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>{litter.puppies?.length}</TableCell>
                            <TableCell>
                              {t(litter.registrationStatus)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          ) : (
            <Typography variant="body">{t("noLitterRegistrations")}</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    )
  );
}
