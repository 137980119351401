import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

// Recoil
import {
  useRecoilValue,
} from "recoil";
import {
  adminAllUsersState,
  createAdminAllOrgDamsState,
  createAdminAllOrgSiresState,
} from "recoil/adminStates";
import { litterByIdState } from "recoil/publicStates";

// MUI
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Launch } from "@mui/icons-material";

// Local
import { countries } from "constants/countries";
import { getDogType } from "constants/helpers";

// This form is also used for pending dogs and tree dogs
function AdminDogInformation ({
  dog,
  formMethods
}) {
  const [initialDog, setInitialDog] = useState(dog);
  const { organizationPrefix } = useParams();
  const { t, i18n } = useTranslation();

  const users = useRecoilValue(adminAllUsersState);

  // What type of dog is this: registered, pending, or tree?
  const dogType = getDogType(dog);
  const litter = dog?.litter ? useRecoilValue(litterByIdState(dog?.litter)) : null;

  const {
    clearErrors,
    control,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = formMethods;

  const formdata = watch();
  const isDeceased = formdata.isDeceased;
  const owners = formdata.owners;
  const breeder = formdata.breeder;

  // State variable for initial owners
  const damsState = createAdminAllOrgDamsState(organizationPrefix);
  const siresState = createAdminAllOrgSiresState(organizationPrefix);
  const femaleDogs = useRecoilValue(damsState);
  const maleDogs = useRecoilValue(siresState);
  
  const [selectedCountry, setSelectedCountry] = useState(
    dog?.countryOfOrigin
      ? countries.find((c) => c.code === dog.countryOfOrigin.toUpperCase())
      : countries.find((c) => c.code === i18n.language.toUpperCase())
  );

  const [selectedSire, setSelectedSire] = useState(dog?.sire || null);
  const [selectedDam, setSelectedDam] = useState(dog?.dam || null);

  return (
        <Grid container direction="row" spacing={3} alignItems="flex-start">
          <Grid item xs={12} md={6}>
          <Controller
            name="name"
            control={control}
            defaultValue={initialDog?.name || ""}
            rules={{
              required: {
                value: dogType !== "treedog",
                message: "Please fill out this field.",
              },
              validate: dogType !== "treedog" ? (value) => value?.length > 0 || "Please fill out this field." : undefined,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required={dogType !== "treedog"}
                label={t("Name")}
                error={!!errors?.name}
                helperText={errors?.name?.message}
              />
            )}
          />
          </Grid>
        
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors?.microchipNumber}>
              <InputLabel shrink>
                {`Microchip number${dogType !== "treedog" ? " *" : ""}`}
              </InputLabel>
              <Controller
                name="microchipNumber"
                control={control}
                defaultValue={initialDog?.microchipNumber || ""}
                rules={{
                  required: dogType !== "treedog" ? "Please fill out this field." : false,
                  validate: dogType !== "treedog" ? (value) => value?.length > 0 || "Please fill out this field." : undefined,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required={dogType !== "treedog"}
                    label="Microchip number"
                    error={!!errors?.microchipNumber}
                  />
                )}
              />
              <FormHelperText>
                {errors?.microchipNumber && errors?.microchipNumber?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        
          <Grid item xs={12} md={6}>
          <Controller
            name="callname"
            control={control}
            defaultValue={initialDog?.callname || ""}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t("Call name")}
                error={!!errors?.callname}
                helperText={errors?.callname?.message}
              />
            )}
          />
          </Grid>

          <Grid item xs={12} md={6}>
      <FormControl fullWidth error={!!errors?.dateOfBirth}>
        <Controller
          name="dateOfBirth"
          control={control}
          defaultValue={initialDog?.dateOfBirth || null}
          rules={{
            required: {
              value: dogType !== "treedog",
              message: "Please fill out this field.",
            },
            validate: dogType !== "treedog" ? (value) => {
              if (value && value.$isDayjsObject) {
                value = value.format('MM/DD/YYYY');
              }
              if (value) return (dayjs(value).isValid() || "Invalid date.");
            } : undefined,
          }}
          render={({ field: { ref, name, ...field } }) => (
            <DatePicker
              {...field}
              inputRef={ref}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required={dogType !== "treedog"}
                  label="Date of birth"
                  error={!!errors?.dateOfBirth}
                />
              )}
              disableFuture
            />
          )}
        />
        <FormHelperText>
          {errors?.dateOfBirth && errors?.dateOfBirth?.message}
        </FormHelperText>
      </FormControl>
    </Grid>
        
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors?.sex}>
              <InputLabel shrink>Sex *</InputLabel>
              <Controller
                name="sex"
                control={control}
                defaultValue={initialDog?.sex || ""}
                rules={{
                  minLength: 1,
                  required: "Required",
                }}
                render={({ field: { ref, name, ...field } }) => (
                  <Select {...field} fullWidth required label="Sex">
                    <MenuItem value="female">female</MenuItem>
                    <MenuItem value="male">male</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText>{errors?.sex?.message}</FormHelperText>
            </FormControl>
          </Grid>
        
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors?.countryOfOrigin}>
              <Controller
                name="countryOfOrigin"
                control={control}
                defaultValue={selectedCountry || null}
                rules={{
                  required: {
                    value: dogType !== "treedog",
                    message: "Please fill out this field.",
                  }
                }}
                render={({ field: { onChange, value, ...props } }) => (
                  <Autocomplete
                    fullWidth
                    options={countries}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    getOptionLabel={(option) => option["en"]}
                    renderOption={(props, option) => (
                      <li {...props} key={option.code}>
                        {option["en"]} {option.code}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={dogType !== "treedog"}
                        label="Country of origin"
                        error={!!errors?.countryOfOrigin}
                        helperText={errors?.countryOfOrigin?.message}
                      />
                    )}
                    value={value}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                  />
                )}
              />
            </FormControl>
          </Grid>        
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Controller
                    name="isDeceased"
                    control={control}
                    defaultValue={initialDog?.isDeceased || false}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />
                }
                label="Mark as deceased"
              />
            </FormControl>
        
            {isDeceased && (
              <>
                <FormControl fullWidth error={!!errors?.dateOfDeath}>
                  <InputLabel shrink>Date of death</InputLabel>
                  <Controller
                    name="dateOfDeath"
                    control={control}
                    defaultValue={initialDog?.dateOfDeath || null}
                    rules={{
                      validate: (value) => {
                        if (value && value.$isDayjsObject) {
                          value = value.format('MM/DD/YYYY');
                        }
                        if (value) return (dayjs(value).isValid() || "Invalid date.");
                      },
                    }}
                    render={({ field: { ref, name, ...field } }) => (
                      <DatePicker
                        {...field}
                        inputRef={ref}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                  <FormHelperText>
                    {errors?.dateOfDeath && errors?.dateOfDeath?.message}
                  </FormHelperText>
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth error={!!errors?.causeOfDeath}>
                  <InputLabel shrink>Cause of death</InputLabel>
                  <Controller
                    name="causeOfDeath"
                    control={control}
                    defaultValue={initialDog?.causeOfDeath || ""}
                    render={({ field }) => (
                      <TextField {...field} fullWidth />
                    )}
                  />
                  <FormHelperText>
                    {errors?.causeOfDeath && errors?.causeOfDeath?.message}
                  </FormHelperText>
                </FormControl>
              </>
            )}
          </Grid>
        
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Owners</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors?.owners}>
              <Controller
                name="owners"
                control={control}
                defaultValue={initialDog?.owners || []}
                rules={{
                  required: {
                    value: dogType !== "treedog",
                    message: "Please fill out this field.",
                  },
                  validate: dogType !== "treedog" ? (value) => value?.length > 0 || "Please fill out this field." : undefined,
                }}
                render={({ field: { onChange, value, ...field } }) => (
                  <Autocomplete
                    multiple
                    options={users}
                    getOptionLabel={(option) => {
                      return `${option?.name} ${option?.email ? " - " : ""} ${option?.email || " "}`;
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {option.name} {" ("}
                        {option.email}
                        {") "}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value?._id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Owners"
                        error={!!errors?.owners}
                      />
                    )}
                    value={value}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    {...field}
                  />
                )}
              />
              <FormHelperText>{errors?.owners && errors?.owners?.message}</FormHelperText>
            </FormControl>
          </Grid>
        
          <Grid item xs={12} md={6}>
            <Typography variant="h5">Owners preview</Typography>
            <List>
              {owners &&
                owners?.map((owner, i) => {
                  return (
                    owner && (
                      <ListItem
                        key={owner._id}
                        secondaryAction={owner.breederId || ""}
                      >
                        <ListItemText
                          primary={`${owner.name}`}
                          secondary={`${owner?.email || ""} 
                        (${owner?._id || ""})`}
                        />
                      </ListItem>
                    )
                  );
                })}
            </List>
          </Grid>
        
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Breeder</Typography>
            </Divider>
          </Grid>
          {breeder === "hidden" ? (
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>
                  This dog has breeder that is outside of your organization
                </AlertTitle>
                Managers can not edit breeder information if the breeder is part
                of a different organization. Please contact the administrator
                for more information.
              </Alert>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors?.breeder}>
                  <Controller
                    name="breeder"
                    defaultValue={initialDog?.breeder || null}
                    onChange={([, data]) => data}
                    control={control}
                    render={({ field: { onChange, ...props } }) => (
                      <Autocomplete
                        fullWidth
                        options={users}
                        getOptionLabel={(option) =>
                          `${option.name} (${option.email})`
                        }
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {option.name} {" ("}
                            {option.email}
                            {") "}
                          </li>
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option._id == value._id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Breeder"
                            error={!!errors?.breeder} />
                        )}
                        onChange={(e, data) => onChange(data)}
                        {...props}
                      />
                    )}
                  />
                  <FormHelperText>{errors?.breeder && errors?.breeder?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">Breeder preview</Typography>
        
                <List>
                  {breeder && (
                    <ListItem secondaryAction={breeder?.regNumber}>
                      <ListItemText
                        primary={`${breeder?.name}`}
                        secondary={`${breeder?.email || ""} (${breeder?._id})`}
                      />
                    </ListItem>
                  )}
                </List>
              </Grid>
            </>
          )}
        
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Family</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors?.sire}>
              <Controller
                name="sire"
                render={({ field: { onChange, ...props } }) => (
                  <Autocomplete
                    fullWidth
                    options={maleDogs}
                    getOptionLabel={(option) =>
                      `${option.name} ${option.regNumber || ""}`
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {option.name} {option.regNumber || ""}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option._id == value._id
                    }
                    autoComplete
                    noOptionsText={"no data"}
                    renderInput={(params) => (
                      <TextField {...params} label="Sire" error={!!errors?.sire} />
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                  />
                )}
                onChange={([, data]) => data}
                defaultValue={selectedSire || null}
                control={control}
              />
              <FormHelperText>{errors?.sire && errors?.sire?.message}</FormHelperText>
            </FormControl>
            <List sx={{ mt: 2 }}>
              <ListItem>
                <Typography variant="h5">{t("Sire preview")}</Typography>
              </ListItem>
              {formdata.sire && (
                <ListItem>
                  <ListItemButton
                    href={`/${organizationPrefix}/admin/dog/${formdata.sire._id}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${formdata.sire.name} ${
                        formdata.sire.regNumber || ""
                      }`}
                      secondary={`${formdata.sire._id}`}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors?.dam}>
              <Controller
                name="dam"
                render={({ field: { onChange, ...props } }) => (
                  <Autocomplete
                    fullWidth
                    options={femaleDogs}
                    getOptionLabel={(option) =>
                      `${option.name} ${option.regNumber || ""}`
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {option.name} {option.regNumber || ""}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option._id == value._id
                    }
                    autoComplete
                    noOptionsText={"no data"}
                    renderInput={(params) => (
                      <TextField {...params} label="Dam" error={!!errors?.dam} />
                    )}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                  />
                )}
                onChange={([, data]) => data}
                defaultValue={selectedDam || null}
                control={control}
              />
              <FormHelperText>{errors?.dam && errors?.dam?.message}</FormHelperText>
            </FormControl>
            <List sx={{ mt: 2 }}>
              <ListItem>
                <Typography variant="h5">{t("Dam preview")}</Typography>
              </ListItem>
              {formdata.dam && (
                <ListItem>
                  <ListItemButton
                    href={`/${organizationPrefix}/admin/dog/${formdata.dam._id}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${formdata.dam.name} ${
                        formdata.dam.regNumber || ""
                      }`}
                      secondary={`${formdata.dam._id}`}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Grid>

          {litter ? (
            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <Typography variant="h5">{t("Part of Litter")}</Typography>
                </ListItem>
                  <ListItemButton
                    href={`/${organizationPrefix}/admin/litter/${litter._id}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText 
                      primary={
                        litter.name 
                          ? litter.name 
                          : `${litter.sire.name} x ${litter.dam.name}`
                      } 
                    />
                   </ListItemButton>
              </List>
            </Grid>
          ) : null}      
      </Grid>
  );
}

export default AdminDogInformation;
