import React, { useState } from "react";
import {
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

// MUI
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

// Local
import DogInformationTesting from "./dogInformationComponents/dogInformationTesting.js";
import DogInformationBasic from "./dogInformationComponents/dogInformationBasic.js";
import DogInformationBreeding from "./dogInformationComponents/dogInformationBreeding.js";
import DogInformationImages from "./dogInformationComponents/dogInformationImages.js";
import DogInformationCoat from "./dogInformationComponents/dogInformationCoat.js";
import DogInformationSize from "./dogInformationComponents/dogInformationSize.js";
import DogInformationAttachments from "./dogInformationComponents/dogInformationAttachments.js";

export default function DogInformation({ dog, setDog, formErrors }) {
  const { t } = useTranslation();
  const formMethods = useFormContext();

  const [expanded, setExpanded] = useState(false);
  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
  <Grid container spacing={3}>
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Divider textAlign="left" sx={{ mb: 2 }}>
        <Typography variant="h2">{t("Dog's information")}</Typography>
      </Divider>
      <Typography variant="body">
        {t("formInfoText")}
        <b>{t("ownerInfoText3")}</b>
      </Typography>
    </Grid>

    <Grid item xs={12}>
{/* UNCOMMENTME once testing works again
      <Accordion
        expanded={expanded === "dogInfoTesting"}
        onChange={handleExpandChange("dogInfoTesting")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="dogInfoTesting-content"
          id="dogInfoTesting-header"
        >
          <Typography variant="h5">{t("Test Results")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DogInformationTesting formMethods={formMethods} />
        </AccordionDetails>
      </Accordion>
*/ }

      <Accordion
        expanded={expanded === "dogInfoBasic"}
        onChange={handleExpandChange("dogInfoBasic")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="dogInfoBasic-content"
          id="dogInfoBasic-header"
        >
          <Typography variant="h5">{t("Basic Information")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DogInformationBasic formMethods={formMethods} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "dogInfoImages"}
        onChange={handleExpandChange("dogInfoImages")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="dogInfoImages-content"
          id="dogInfoImages-header"
        >
          <Typography variant="h5">{t("Images")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DogInformationImages formMethods={formMethods} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "dogInfoBreeding"}
        onChange={handleExpandChange("dogInfoBreeding")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="dogInfoBreeding-content"
          id="dogInfoBreeding-header"
        >
          <Typography variant="h5">{t("Breed Description (Breed or Mix)")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DogInformationBreeding formMethods={formMethods} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "dogInfoCoat"}
        onChange={handleExpandChange("dogInfoCoat")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="dogInfoCoat-content"
          id="dogInfoCoat-header"
        >
          <Typography variant="h5">{t("Coat")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DogInformationCoat formMethods={formMethods} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "dogInfoSize"}
        onChange={handleExpandChange("dogInfoSize")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="dogInfoSize-content"
          id="dogInfoSize-header"
        >
          <Typography variant="h5">{t("Size")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DogInformationSize formMethods={formMethods} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "dogInfoAttachments"}
        onChange={handleExpandChange("dogInfoAttachments")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="dogInfoAttachments-content"
          id="dogInfoAttachments-header"
        >
          <Typography variant="h5">{t("Attachments")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DogInformationAttachments formMethods={formMethods} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  </Grid>
  );
}
