import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  getGeneticTestTypeByIdState,
  getResultProviderByIdState,
} from "recoil/publicStates";
import { TableCell } from "@mui/material";
import { getKruValueLabel } from "constants/helpers";

export default function GeneticResultSource({ result }) {
  const error = " ";

  const source = useRecoilValue(getResultProviderByIdState(result.source));

  return <TableCell>{source?.name || error}</TableCell>;
}
export function GeneticResultResults({ result }) {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const error = " ";

  const subject = useRecoilValue(getGeneticTestTypeByIdState(result.subject));

  return (
    <p>
      {`${subject.name} (${subject.marker}): `}
      {getKruValueLabel(result.result, lang) || error}
    </p>
  );
}
