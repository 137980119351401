import React from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState } from "recoil";
import { litterRegistrationState } from "recoil/registrationStates";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  useTheme,
} from "@mui/material";

// Local
import { createHandleInputChange } from "constants/helpers";
import {
  ImageUpload,
  FileAttachments,
  LinkAttachments,
  NoteAttachments,
} from "components/Attachments";

export default function LitterAttachments() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [formData, setFormData] = useRecoilState(litterRegistrationState);
  const { puppies } = formData;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("attachments")}</Typography>
          </Divider>
        <Typography variant="h5">{t("Images")}</Typography>
        </Grid>
        
        <Grid item xs={12} sx={{ mb: 2 }}>
            {puppies && puppies.map((puppy, index) => (
              <Grid container key={`images-${index}`}>
                <Grid item xs={12} md={7} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ mr: 2 }}>{puppy.name}</Typography>
                  <ImageUpload category={`images-${index}`} recoilState={litterRegistrationState} />
                </Grid>
              </Grid>
            ))}
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12} mb={2}>
            <FileAttachments recoilState={litterRegistrationState} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LinkAttachments recoilState={litterRegistrationState} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <NoteAttachments recoilState={litterRegistrationState} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
  }