import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilValue } from "recoil";
import { currentDogOrgState, 
  currentDogState,
  getOrgByPrefixState,
  organizationsState } from "recoil/publicStates";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Paper,
  Divider,
  Typography
} from "@mui/material";

// Components
import AttachmentList from "components/formComponents/attachmentList";
import BasicInformation from "components/dogProfileComponents/BasicInformation";
import RegistrationInfo from "components/dogProfileComponents/RegistrationInfo";
import GeneticsInfo from "components/dogProfileComponents/GeneticResults";
import HealthInfo from "components/dogProfileComponents/HealthResults";
import CompetitionInfo from "components/dogProfileComponents/CompetitionResults";
import SiblingsInfo from "components/dogProfileComponents/Siblings";
import LittersInfo from "components/dogProfileComponents/Litters";
import OverviewCard from "components/dogProfileComponents/OverviewCard";
import PedigreeTable from "components/pedigreeComponents/PedigreeTable";
import { imageNode } from "components/pedigreeComponents/nodeElements";
import OrganizationCard from "elements/OrganizationCard";
import { getOrgAndMore } from "constants/helpers";

export default function DogProfile() {
  const { t } = useTranslation();
  const { id, organizationPrefix } = useParams();

  const organization = useRecoilValue(getOrgByPrefixState(organizationPrefix));

  if (!organization) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No organization found with abbreviation")} <i>{organizationPrefix}</i></Typography>
      </Paper>
    );
  }

  // If id > 12 characters, it is a uuid and we use currentDogState
  // Otherwise it is a regNumber and we use currentDogState with organization
  const dog = id.length > 12 ? useRecoilValue(currentDogState(id)) : useRecoilValue(currentDogOrgState({id: id, organization: organization}));
  
  if (!dog) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No dog found in this organization with number")} <i>{id}</i></Typography>
      </Paper>
    );
  }

  const orgWithReg = getOrgAndMore(dog, organization);
  if ( ! orgWithReg) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No dog found in this organization with ID")} <i>{id}</i></Typography>
      </Paper>
    );
  }
  
  const allOrganizations = useRecoilValue(organizationsState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <OrganizationCard organization={organization}/>
      <Grid container spacing={5}>
        <Grid xs={12}>
          <Paper>
            <OverviewCard
              dog={dog}
              organization={organization}
            />
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h3">{t("basicInformation")}</Typography>
          </Divider>
          <BasicInformation
            dog={dog} organization={organization}
          />
        </Grid>
        <Grid xs={12}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            {/* Replace when we actually have certificates:
		<Typography variant="h3">{`${t("registration")} & ${t(
              "certificates"
              ).toLowerCase()}`}</Typography> */}
            <Typography variant="h3">{t("registration")}</Typography>
          </Divider>
          <RegistrationInfo dog={dog} organization={organization} />
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Attachments</Typography>
          </Divider>
          <AttachmentList parentDoc={dog} />
        </Grid>
        { /* DELETEME
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Additional info</Typography>
          </Divider>              
          <List>
            {additionalInfo.map((info, index) => (
              <ListItem key={index}>
                <ListItemText primary={info} />
              </ListItem>
            ))}
          </List>
          </Grid> */ }
          
        {dog?.health && dog.health.length > 0 ? (
          <Grid xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("healthTestResults")}</Typography>
            </Divider>
            <HealthInfo dog={dog} />
          </Grid>
        ) : null}
        {dog.geneticResults?.length > 0 ? (
          <Grid xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("geneticTestResults")}</Typography>
            </Divider>
            <GeneticsInfo dog={dog} />
          </Grid>
        ) : null}
        {dog?.competition?.length > 0 ? (
          <Grid xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("competitionResults")}</Typography>
            </Divider>
            <CompetitionInfo dog={dog} />
          </Grid>
        ) : null}
        {dog ? (
          <Grid xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("pedigree")}</Typography>
            </Divider>
            <Paper>
              <div id="treeWrapper" style={{ width: "100%", height: "800px" }}>
                <PedigreeTable
                  dog={dog}
                  organization={organization}
                  nodeSize={{ x: 200, y: 100 }}
                  translate={{ x: 100, y: 400 }}
                  zoom={0.8}
                  renderCustomNodeElement={(node) => imageNode({ node, organization, allOrganizations })}
                  />
              </div>
            </Paper>
          </Grid>
        ) : null}
        {dog ? (
          <Grid xs={12} md={6}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("siblings")}</Typography>
            </Divider>
            <SiblingsInfo dog={dog} organization={organization} />
          </Grid>
        ) : null}
        {dog ? (
          <Grid xs={12} md={6}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("litters")}</Typography>
            </Divider>
            <LittersInfo parent={dog} organization={organization} />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
