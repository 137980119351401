import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Recoil
import { breederIdState, dogsByOwnerIdState, littersByBreederIdState, getOrgByPrefixState } from "recoil/publicStates";
import { useRecoilValue } from "recoil";

// MUI
import { Typography, Grid, Paper, Divider } from "@mui/material";

// Components
import BreederInfoBox from "components/breederProfileComponents/BreederInfoBox";
//import BreederStatistics from "components/breederProfileComponents/BreederStatistics";
import { DogCard } from "elements/DogCard";
import { LitterCard } from "elements/LitterCard";
import { getOrgAndMore } from "constants/helpers";
import OrganizationCard from "elements/OrganizationCard";

export default function BreederProfile() {
  const { t } = useTranslation();
  const { breederId, organizationPrefix } = useParams();

  const organization = useRecoilValue(getOrgByPrefixState(organizationPrefix));

  if (!organization) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No organization found with abbreviation")} <i>{organizationPrefix}</i></Typography>
      </Paper>
    );
  }

  const breeder = useRecoilValue(breederIdState(breederId));
  const displayBreeder = breeder ? getOrgAndMore(breeder, organization)?.displayBreeder : null;
  
  if (!breeder || !displayBreeder) {
    return (
      <Paper square sx={{ p: 2 }}>
        <Typography variant="body">{t("No breeder found in this organization with breeder ID")} <i>{breederId}</i></Typography>
      </Paper>
    );
  }

  const litters = useRecoilValue(littersByBreederIdState(breeder._id));

  // filter litters by organization
  const filteredLitters = litters.filter(litter =>
    litter.organizations.some(org =>
      org.organization._id === organization._id
    )
  );

  const dogs = useRecoilValue(dogsByOwnerIdState(breeder._id));

  const filteredDogs = dogs.filter(dog =>
    dog.organizations.some(org =>
      (org._id === organization._id ||
      org.organization._id === organization._id) &&
      org.regClass !== "reg.classes.unregparent"
    )
  );

  return (
    <>
    <OrganizationCard organization={organization}/>

    <Grid container direction={{ xs: "column", sm: "row" }} spacing={5}>
      <Grid item xs={12}>
        <BreederInfoBox breeder={breeder} />
      </Grid>

      <Grid item xs={12} md={8}>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h3">{t("Breeder's dogs")}</Typography>
        </Divider>
        {filteredDogs.length ? (
          filteredDogs?.map((dog) => (
            <DogCard dog={dog} organization={organization} key={dog._id} />
          ))
        ) : (
          <Paper square sx={{ p: 2 }}>
            <Typography variant="body">{t("No registered dogs")}</Typography>
          </Paper>
        )}
      </Grid>

      { /* <Grid item xs={12} md={4}>
        <BreederStatistics breeder={breeder} litters={litters} />
      </Grid> */ }
      <Grid item xs={12} md={8}>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h3">{t("breedersLitters")}</Typography>
        </Divider>
        {filteredLitters.length ? (
          filteredLitters?.map((litter) => (
            <LitterCard litter={litter} organization={organization} key={litter._id} />
          ))
        ) : (
          <Paper square sx={{ p: 2 }}>
            <Typography variant="body">{t("noLitters")}</Typography>
          </Paper>
        )}
      </Grid>

    </Grid>
    </>
  );
}
