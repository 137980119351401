import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { loadingState, snackBarState } from "recoil/globalStates";
import { selectedDocByIdState } from "recoil/adminStates";
import { attachmentsModState } from "recoil/adminStates";

// Routes
import { updateDog } from "api/adminRoutes";
// import { addAttachment, deleteAttachment } from "api/privateRoutes";
// import { getAttachments } from "api/adminRoutes";
// import { getAttachments } from "api/publicRoutes";

// Components
import DogForm from "../forms/dogForm";
import { AdminNotFound } from "components/adminDashboardComponents/adminNotFound";

function AdminDogView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [attachmentsMod, setAttachmentsMod] = useRecoilState(attachmentsModState);
  const dog = useRecoilValue(selectedDocByIdState("dog"));

  const handleSubmit = async (accessToken, updatedDog, id) => {
    setLoading(true);

    // Check if there are any items in the "delete" fields of attachmentsMod
    const hasDeletions =
      attachmentsMod.images.delete.length > 0 ||
      attachmentsMod.files.delete.length > 0 ||
      attachmentsMod.links.delete.length > 0 ||
      attachmentsMod.text.delete.length > 0;

    if (hasDeletions) {
      const confirmDelete = window.confirm("Are you sure you want to delete attachments?");
      if (!confirmDelete) {
        setLoading(false);
        return;
      }
    }
    
    const updateResponse = await updateDog(id, updatedDog, attachmentsMod, { accessToken });
    if (updateResponse?.error) {
      setSnackbarState({
        message: "Dog not updated: " + updateResponse.message,
        severity: "error",
        open: true,
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    setSnackbarState({
      message: "Dog updated successfully",
      severity: "success",
      open: true,
    });
  };


  return dog ? (
    
    <DogForm
      dog={dog}
      emitHandleSubmit={handleSubmit}
    />
  ) : (
    <AdminNotFound docType="Dog" />
  );
}

export default AdminDogView;
