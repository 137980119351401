import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

// MUI
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

// Local
import TreedogInformationTesting from "./treedogInformationComponents/treedogInformationTesting.js";
import TreedogInformationBasic from "./treedogInformationComponents/treedogInformationBasic.js";
import TreedogInformationBreeding from "./treedogInformationComponents/treedogInformationBreeding.js";
import TreedogInformationImages from "./treedogInformationComponents/treedogInformationImages.js";
import TreedogInformationCoat from "./treedogInformationComponents/treedogInformationCoat.js";
import TreedogInformationSize from "./treedogInformationComponents/treedogInformationSize.js";
import TreedogInformationAttachments from "./treedogInformationComponents/treedogInformationAttachments.js";

export default function TreedogInformation({ treedog, setTreedog, formErrors }) {
  const { t } = useTranslation();
  const formMethods = useFormContext();

  const [expanded, setExpanded] = useState(false);
  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h2">{t("Tree dog information")}</Typography>
        </Divider>
      </Grid>

      <Grid item xs={12}>
      { /*
        <Accordion
          expanded={expanded === "treedogInfoTesting"}
          onChange={handleExpandChange("treedogInfoTesting")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="treedogInfoTesting-content"
            id="treedogInfoTesting-header"
          >
            <Typography variant="h5">{t("Test Results")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TreedogInformationTesting formMethods={formMethods} />
          </AccordionDetails>
        </Accordion>
*/ }
        <Accordion
          expanded={expanded === "treedogInfoBasic"}
          onChange={handleExpandChange("treedogInfoBasic")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="treedogInfoBasic-content"
            id="treedogInfoBasic-header"
          >
            <Typography variant="h5">{t("Basic Information")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TreedogInformationBasic formMethods={formMethods} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "treedogInfoImages"}
          onChange={handleExpandChange("treedogInfoImages")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="treedogInfoImages-content"
            id="treedogInfoImages-header"
          >
            <Typography variant="h5">{t("Images")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TreedogInformationImages formMethods={formMethods} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "treedogInfoBreeding"}
          onChange={handleExpandChange("treedogInfoBreeding")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="treedogInfoBreeding-content"
            id="treedogInfoBreeding-header"
          >
            <Typography variant="h5">{t("Breed Description (Breed or Mix)")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TreedogInformationBreeding formMethods={formMethods} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "treedogInfoCoat"}
          onChange={handleExpandChange("treedogInfoCoat")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="treedogInfoCoat-content"
            id="treedogInfoCoat-header"
          >
            <Typography variant="h5">{t("Coat")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TreedogInformationCoat formMethods={formMethods} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "treedogInfoSize"}
          onChange={handleExpandChange("treedogInfoSize")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="treedogInfoSize-content"
            id="treedogInfoSize-header"
          >
            <Typography variant="h5">{t("Size")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TreedogInformationSize formMethods={formMethods} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "treedogInfoAttachments"}
          onChange={handleExpandChange("treedogInfoAttachments")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="treedogInfoAttachments-content"
            id="treedogInfoAttachments-header"
          >
            <Typography variant="h5">{t("Attachments")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TreedogInformationAttachments />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}