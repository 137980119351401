import React from "react";
import { Link } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { kruValuesState, userState } from "recoil/globalStates";
import { getOrgByPrefixState } from "recoil/publicStates";
import {
  dogRegistrationState
} from "recoil/registrationStates";

// MUI
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material";

// Local
import {
  createHandleInputChange,
  displayDate,
  getKruValueLabel,
  getNameByLang,
  getPaymentAmount,
} from "constants/helpers";

export default function DogSummary() {
  const { t, i18n } = useTranslation();
  const { organizationPrefix } = useParams();
  const org = useRecoilValue(getOrgByPrefixState(organizationPrefix));
  const language = i18n.language;
  const kruValues = useRecoilValue(kruValuesState);
  const [formData, setFormData] = useRecoilState(dogRegistrationState);

  const fileAttachments = formData.attachments.files || [];
  const linkAttachments = formData.attachments.links || [];
  const textAttachments = formData.attachments.text || [];
  const headImage = formData.attachments.images.headImage?.url;
  const sideImage = formData.attachments.images.sideImage?.url;

  const handleInputChange = createHandleInputChange(setFormData);

  const methods = useFormContext();
  const { control } = methods;

  const user = useRecoilValue(userState);
  let owner = formData.owner;
  if (owner === undefined || owner === null) {
    owner = user;
  }

  // Set price. Note it will be null when the form refreshes
  // just after submission.
  let price = getPaymentAmount(user, "dog", org);
  price = price == null ? 0 : price.toFixed(2);

  const declaredTypes = () => {
    const breeds = kruValues.filter((i) => i.category === "ancestry" && i._id.startsWith("breeds"));
    const typeName = (type) => {
      return getNameByLang(type, breeds, language);
    };

    if (formData.declaredBreed) {
      return typeName(formData.declaredBreed);
    } else if (formData.declaredMix) {
      return typeName(formData.declaredMix);
    } else if (formData.declaredType1 && formData.declaredType2) {
      return `${typeName(formData.declaredType1)} / ${typeName(formData.declaredType2)} mix`;
    } else if (formData.declaredType1) {
      return `${typeName(formData.declaredType1)} mix`;
    }
    return "-";
  };

  const modifiers = []
    .concat(formData.alocusmodifiers || [])
    .concat(formData.merlemodifiers || [])
    .concat(formData.othermodifiers || []);
  const markings = []
    .concat(formData.whitemarkings || [])
    .concat(formData.spots || [])
    .concat(formData.othermarkings || []);
  const coatQualities = []
    .concat(formData.coatqualities || [])
    .concat(formData.coatcurliness || []);
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{formData.name}</Typography>
        {formData.callname && (
          <Typography variant="h4">{`(${formData.callname})`}</Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography variant="h3">
            {formData["class"] !== "reg.classes.unregparent" ?
              t("owner") :
              t("Submitter")}
          </Typography>
        </Divider>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              {owner?.name}
              <br />
              {owner?.email || t("noData")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <Typography variant="body1">
              {t("Breeding prefix")}: {owner?.breedingPrefix || t("Not set")}
              <br />
              {t("permissionToPublishName")}:{" "}
              {owner?.permissions?.name ? t("yes") : t("no")}
              <br />
              {t("permissionToPublishCity")}:{" "}
              {owner?.permissions?.city ? t("yes") : t("no")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      { /*
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("registry")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">
          {formData.registry?.name}
          </Typography>
          <Typography variant="body2">
          {t("Registration class:")} {getKruValueLabel(formData.class, language)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Button
          href={formData.registry?.url}
          target="_blank"
          endIcon={<Launch />}
        >
          {t("registryGuidelines")}
        </Button>
      </Grid>
      */ }
      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography variant="h3">{t("Sire") + " & " + t("Dam")}</Typography>
        </Divider>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t("sire")}</Typography>
            <Typography variant="body1">
              {formData.sire?.name || t("noData")} {formData.sire?.regNumber}
              <br />
              {formData.sire && t("DOB") + " " +
               displayDate(formData.sire?.dateOfBirth)}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t("dam")}</Typography>
            <Typography variant="body1">
              {formData.dam?.name || t("noData")} {formData.dam?.regNumber}
              {formData.dam && t("DOB") + " " +
               displayDate(formData.dam?.dateOfBirth)}
            </Typography>
            <br />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("Dog information")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("dob")}</TableCell>
                <TableCell>{t("countryOfOrigin")}</TableCell>
                <TableCell>{t("microchipNumber")}</TableCell>
                <TableCell>{t("Breeder")}</TableCell>
                <TableCell>{t("Breed")}</TableCell>
                <TableCell>{t("height")}</TableCell>
                <TableCell>{t("weight")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {displayDate(formData.dateOfBirth) ||
                    t("notProvided")}
                </TableCell>
                <TableCell>
                  {formData.countryOfOrigin?.[language] || "-"}
                </TableCell>
                <TableCell>{formData.microchipNumber}</TableCell>
                <TableCell>{formData.breeder?.name || "-"}</TableCell>
                <TableCell>
                  {declaredTypes()}
                </TableCell>
                <TableCell>{formData.height || "-"}</TableCell>
                <TableCell>{formData.weight || "-"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("color")}</TableCell>
                <TableCell>{t("Modifiers")}</TableCell>
                <TableCell>{t("markings")}</TableCell>
                <TableCell>{t("coatType")}</TableCell>
                <TableCell>{t("coatQualities")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {getKruValueLabel(formData.color, language) || "-"}
                </TableCell>
                <TableCell>
                  {(modifiers &&
                    modifiers.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                    "-"}
                </TableCell>
                <TableCell>
                  {(markings &&
                    markings.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                    "-"}
                </TableCell>
                <TableCell>
                  {getKruValueLabel(formData.coattype, language) || "-"}
                </TableCell>
                <TableCell>
                  {(coatQualities &&
                    coatQualities.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                    "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </Grid>

      {formData.embarkURL && (
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("Test results")}</Typography>
        </Divider>
        <Typography variant="body1">
          {"Embark: "}
          <Link to={formData.embarkURL} target="_blank" rel="noopener noreferrer">{formData.embarkURL}</Link>
        </Typography>
      </Grid>
      )}

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("Attachments")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableBody>
                <TableRow>
                  <TableCell>{t("Images")}</TableCell>
                  <TableCell>
                  {headImage ? (
                    <img
                      src={headImage}
                      alt={t("Head closeup")}
                      style={{ maxWidth: "100%", maxHeight: "80vh", objectFit: "contain" }}
                    />
                  ) : (
                    <p>{t("No head image available")}</p>
                  )}
                  </TableCell>
                  <TableCell>
                  {sideImage ? (
                    <img
                      src={sideImage}
                      alt={t("Side view")}
                      style={{ maxWidth: "100%", maxHeight: "80vh", objectFit: "contain" }}
                    />
                  ) : (
                    <p>{t("No side image available")}</p>
                  )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Files")}</TableCell>
                  <TableCell>
                    {fileAttachments.map((a, i) => (
                      <p key={i}>{a.name}</p>
                    )) || "-"}
                  </TableCell>
                </TableRow>
                  <TableRow>
                    <TableCell>{t("Links")}</TableCell>
                    <TableCell>
                      {linkAttachments.map((a, i) => (
                        <p key={i}>{a}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Notes")}</TableCell>
                    <TableCell>
                      {textAttachments.map((a, i) => (
                        <p key={i}>
                          {a.length > 50 ? `${a.substring(0, 40)}...` : a}
                        </p> 
                      )) || "-"}
                    </TableCell>
                  </TableRow>
              </TableBody>
          </Table>
        </TableContainer>
      </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h3">{t("termsAndPayment")}</Typography>
            </Divider>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Description")}</TableCell>
                    <TableCell>{t("Price")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{t("dogRegistration")}</TableCell>
                    <TableCell>{price} USD</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="right">
                      <Typography variant="caption">
                        {t("Payment will be collected by registering organization when your registration is approved.")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Stack spacing={2} sx={{ mt: 3 }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Controller
                      name="dataVerified"
                      control={control}
                      defaultValue={formData.dataVerified}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          value={formData.dataVerified}
                          onChange={(e) => handleInputChange(e, field)}
                          checked={field.value}
                        />
                      )}
                    />
                  }
                  label={(owner === user && formData["class"] !== "reg.classes.unregparent") ? t("ownerConfirmation") : t("I declare that the information I have given is true.")}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Controller
                      name="orgAgreement"
                      control={control}
                      defaultValue={formData.orgAgreement}
                      render={({ field }) => (
                        <Checkbox {...field}
                          checked={field.value}
                          value={formData.orgAgreement}
                          onChange={(e) => handleInputChange(e, field)}
                          />
                      )}
                    />
                  }
                  label={t("termsConfirmation", {
                    registryName: org.name,
                  })}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Controller
                      name="platformAgreement"
                      control={control}
                      defaultValue={formData.platformAgreement}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          value={formData.platformAgreement}
                          onChange={(e) => handleInputChange(e, field)}
                          checked={field.value}
                          />
                      )}
                    />
                  }
                  label={
                    <>
                      {t("I have read and agreed to the ")}
                      <Link to="/terms" target="_blank" rel="noopener noreferrer">{t("FunctionalDogData terms of use.")}</Link>
                    </>
                  }                />
              </FormControl>
            </Stack>
          </Grid>
    </Grid>
  );
}
