import React, { useEffect, useState } from "react";
import {
  Controller,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState } from "recoil";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import {
  FormControl,
  Grid,
  Paper,
  TextField,
} from "@mui/material";

export default function TreedogInformationBreeding({ formMethods }) {
  const { t, i18n } = useTranslation();
  const {
    control,
    formState: { errors },
  } = formMethods;

  const [formData, setFormData] = useRecoilState(treedogAdditionState);


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name="heightIn"
                  control={control}
                  defaultValue={formData.heightIn || ""}
                  rules={{
                    min: {
                      value: 1,
                      message: t("Height must be a positive number"),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      label={t("Height - in")}
                      error={!!errors?.heightIn}
                      helperText={errors?.heightIn?.message}
                      onChange={(e) => {
                        field.onChange(e);
                        setFormData({
                          ...formData,
                          height: Math.round(e.target.value * 2.54),
                          heightIn: e.target.value,
                        });
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name="height"
                  control={control}
                  defaultValue={formData.height || ""}
                  rules={{
                    min: {
                      value: 1,
                      message: t("Height must be a positive number"),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={formData.height}
                      type="number"
                      label={t("Height - cm")}
                      error={errors?.height && true}
                      helperText={errors && errors?.height?.message}
                      onChange={(e) => {
                        field.onChange(e);
                        setFormData({
                          ...formData,
                          height: e.target.value,
                          heightIn: Math.round(e.target.value / 2.54),
                        });
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    
      <Grid item xs={12} sm={3}>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name="weightLb"
                  control={control}
                  defaultValue={formData.weightLb || ""}
                  rules={{
                    min: {
                      value: 1,
                      message: t("Weight must be a positive number"),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      label={t("Weight - lb")}
                      error={errors?.weightLb && true}
                      helperText={errors && errors?.weightLb?.message}
                      onChange={(e) => {
                        field.onChange(e);
                        setFormData({
                          ...formData,
                          weight: Math.round(e.target.value * 0.453592),
                          weightLb: e.target.value,
                        });
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name="weight"
                  control={control}
                  defaultValue={formData.weight || ""}
                  rules={{
                    min: {
                      value: 1,
                      message: t("Weight must be a positive number"),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={formData.weight}
                      type="number"
                      label={t("Weight - kg")}
                      error={errors?.weight && true}
                      helperText={errors && errors?.weight?.message}
                      onChange={(e) => {
                        field.onChange(e);
                        setFormData({
                          ...formData,
                          weight: e.target.value,
                          weightLb: Math.round(e.target.value / 0.453592),
                        });
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
