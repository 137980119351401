import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// Recoil
import { useRecoilValue } from "recoil";
import { organizationsState } from "recoil/publicStates";

// MUI
import { Typography, Divider, Grid, Paper } from "@mui/material";

// Components
import InfoBox from "components/homeComponents/InfoBox";
import OrganizationInfoBox from "components/organizationComponents/OrganizationInfoBox";

export default function Home() {
  const { t } = useTranslation();

  const allOrgs = useRecoilValue(organizationsState);
  
  return (
    <>
      <InfoBox />
      <Divider textAlign="left" sx={{ mb: 2, pt: 2, pb: 2 }}>
          <Typography variant="h2">{t("What is FDD?")}</Typography>
        </Divider>
        <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="body">
          <p>{t("Functional Dog Data (FDD) is a platform for dog registries, breeding co-operatives, and other organizations to store health and pedigree information.")}</p>
          <p>{t("FDD is a service of the ")}
            <Link to="https://functionalbreeding.org">{t("Functional Dog Collaborative")}</Link>.
          </p>
      </Typography>
      </Paper>
      <Divider textAlign="left" sx={{ mb: 2, pt: 2, pb: 2 }}>
          <Typography variant="h2">{t("Member registries")}</Typography>
        </Divider>
      <Grid container direction={{ xs: "column", md: "row" }} spacing={5}>
        {allOrgs.map((org, index) => (
          <Grid item xs={12} md={6} key={index}>
            <OrganizationInfoBox org={org} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
