import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accessTokenState,
  loadingState,
  snackBarState,
} from "recoil/globalStates";
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
// Local
import {
  FileAttachments,
  LinkAttachments,
  NoteAttachments,
} from "components/Attachments";

export default function DogInformationAttachments ({ formMethods }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const accessToken = useRecoilValue(accessTokenState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [formData, setFormData] = useRecoilState(dogRegistrationState);
  const files = formData.attachments.files || [];
  const links = formData.attachments.links || [];
  const notes = formData.attachments.text || [];

  const {
    formState: { errors },
  } = formMethods;


return (
<Grid container>
  <Grid item xs={12} sm={12}>
    <Box mb={2}>
      <Typography variant="body">{t("Attach additional documents here, such as health results or pedigree information.")}</Typography>
    </Box>
    </Grid>
    <Grid item xs={12} sm={12} mb={2}>
      <FileAttachments recoilState={dogRegistrationState} />
    </Grid>

    <Grid item xs={12} sm={12}>
      <LinkAttachments recoilState={dogRegistrationState} />
    </Grid>

    <Grid item xs={12} sm={12}>
      <NoteAttachments recoilState={dogRegistrationState} />
    </Grid>
  </Grid>
);
}