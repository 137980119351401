// PRIVATE API ADMIN ROUTES
import { createDog } from "./privateRoutes";
import { request, adminUrl } from "./common";

// ------------------------  ATTACHMENTS  ---------------------------

export async function getAttachments({ accessToken, query }) {
  const url = adminUrl(`/attachments/query`);
  return request({ url, accessToken, body: { query } });
}

// --------------------------- LITTERS ------------------------------

export async function getAllLitters({
  accessToken,
  query,
  options = { populate: "sire dam puppies breeder organizations.organization" },
}) {
  const url = adminUrl(`/litters/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function getAllPendingLitters({
  accessToken,
  query = { __t:"Pending" },
  options = { populate: "sire dam puppies breeder organizations.organization" },
}) {
  const url = adminUrl(`/litters/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function createNewLitter(document, { accessToken }) {
  const url = adminUrl(`/litters/new`);
  const options = { populate: "sire dam puppies breeder organizations.organization" };
  return request({ url, accessToken, body: { document, options } });
}

export async function getLitterById(
  _id,
  {
    accessToken,
    options = { 
      populate: [
        "sire", 
        "dam", 
        "puppies", 
        "breeder", 
        "organizations.organization", 
        "certificates.certificate", 
      ]
    },
    }
) {
  const url = adminUrl(`/litters/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function createPuppiesFromLitter(litter, pending, { accessToken }) {

  const puppyIds = [];

  // Map puppies into correct form, only if they don't have an id
  const newPuppies = litter.puppies
  .filter((puppy) => !puppy._id) // First, filter out puppies with an _id
  .map((puppy) => { // Then, map over the filtered array
    puppy.markings.unshift(puppy.color);
    puppy.coatqualities.unshift(puppy.coattype);

    return {
      name: puppy.name,
      owners: [litter.breeder],
      microchipNumber: puppy.microchipNumber,
      sex: puppy.sex,
      color: puppy.markings,
      coat: puppy.coatqualities,
      regType: "reg.types.litter",
      regClass: "reg.classes.limited",
      organizations: litter.organizations,
      countryOfOrigin: litter.countryOfOrigin.code,
      breeder: litter.breeder,
      sire: litter.sire,
      dam: litter.dam,
      dateOfBirth: litter.dateOfBirth,
      pendingRegistration: pending
    };
  });

  // Create puppies as registrable dogs
  for (const puppy of newPuppies) {
    const newPuppy = await createDog(puppy, { accessToken });
    puppyIds.push(newPuppy._id);

    if (! pending) {
        const regNum = await registerDog(newPuppy._id, litter.organizations[0].organization.prefix, {
          accessToken,
        });
        if (regNum === null || regNum === undefined) {
          throw new Error(
            "Failed to create registration number for puppy in database."
          );
        }
    }
  }
  return puppyIds;
}

export async function updateLitter(_id, litter, { pending, accessToken, options = {} }) {

  /* Later!
  const newPuppyIds = await createPuppiesFromLitter(litter, pending, { accessToken });

  // Remove puppies without _id, then map over the remaining puppies to get their _id,
  // then add the new puppies to the list of puppies.
  litter.puppies = [
  ...litter.puppies
    .filter((puppy) => puppy._id)
    .map((puppy) => puppy._id),
  ...newPuppyIds
  ];
*/

  const url = adminUrl(`/litters/byId/${_id}`);
  return request({
    url,
    method: "PATCH",
    accessToken,
    body: { update: litter, options },
  });
}

export async function registerLitter(_id, { accessToken }) {
  const url = adminUrl(`/litters/registerById/${_id}`);
  return request({ url, accessToken });
}

export async function deleteLitter(_id, { accessToken }) {
  const url = adminUrl(`/litters/byId/${_id}`);
  return request({ url, method: "DELETE", accessToken });
}

// --------------------------- PENDING DOGS ------------------------------

export async function getAllPendingDogs({
  accessToken,
  query,
  options = {
    populate: "organizations.organization",
  },
}) {
  const url = adminUrl(`/dogs/query`);
  query = { pendingRegistration: true };
  return request({ url, accessToken, body: { query, options } });
}

export async function registerDog(_id, { accessToken }) {
  const url = adminUrl(`/dogs/registerById/${_id}`);
  return request({ url, accessToken });
}

// --------------------------- DOGS ------------------------------

export async function getAllDogs({
  accessToken,
  query = { __t:"Registrable", pendingRegistration: false },
  options = { 
    populate: [
      "organizations.organization", 
    ]
  },
}) {
  const url = adminUrl(`/dogs/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function getFemaleDogsAndTreedogs({
  accessToken,
  query = { sex:"female", pendingRegistration: false },
  options = {},
}) {
  const url = adminUrl(`/dogs/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function getMaleDogsAndTreedogs({
  accessToken,
  query = { sex:"male", pendingRegistration: false },
  options = {},
}) {
  const url = adminUrl(`/dogs/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function getDogById(
  _id,
  {
    accessToken,
    options = { 
      populate: [
        "sire dam breeder owners certificates.certificate organizations.organization", 
      ]
    },
  }
) {
  const url = adminUrl(`/dogs/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function createNewDog(document, { accessToken }) {
  const url = adminUrl(`/dogs/new`);
  return request({ url, accessToken, body: { document } });
}

export async function updateDog(_id, update, attachmentsMod, { accessToken, options }) {
  const url = adminUrl(`/dogs/byId/${_id}`);
  return request({
    url,
    method: "PATCH",
    accessToken,
    body: { update, attachmentsMod, options },
  });
}

export async function deleteDog(_id, { accessToken }) {
  const url = adminUrl(`/dogs/byId/${_id}`);
  return request({ url, method: "DELETE", accessToken });
}

// --------------------------- USERS ------------------------------

export async function getAllUsers({
  accessToken,
  query = {},
  options = {
    lean: true,
    populate: "organizations.organization",
  },
}) {
  const url = adminUrl(`/users/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function getUserById(
  _id,
  {
    accessToken,
    options = {
      lean: true,
    },
  }
) {
  const url = adminUrl(`/users/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function updateUser(_id, update, { accessToken, options = {} }) {
  const url = adminUrl(`/users/byId/${_id}`);
  return request({
    url,
    method: "PATCH",
    accessToken,
    body: { update, options },
  });
}

export async function createNewUser(document, { accessToken }) {
  const url = adminUrl(`/users/new`);
  return request({ url, accessToken, body: { document } });
}

export async function deleteUser(_id, { accessToken }) {
  const url = adminUrl(`/users/byId/${_id}`);
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  COMPETITION RESULTS  ---------------------------

export async function getAllCompetitionResults({
  accessToken,
  query = {},
  options = {
    populate: "parentDoc",
  },
}) {
  const url = adminUrl(`/competitionresults/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function getCompetitionResultById(
  _id,
  { accessToken, options = {} }
) {
  const url = adminUrl(`/competitionresults/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function updateCompetitionResult(
  _id,
  update,
  { accessToken, options = {} }
) {
  const url = adminUrl(`/competitionresults/byId/${_id}`);
  return request({
    url,
    method: "PATCH",
    accessToken,
    body: { update, options },
  });
}

export async function createNewCompetitionResult(document, { accessToken }) {
  const url = adminUrl(`/competitionresults/new`);
  return request({ url, accessToken, body: { document } });
}

export async function deleteCompetitionResult(_id, { accessToken }) {
  const url = adminUrl(`/competitionresults/byId/${_id}`);
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  TREEDOGS  ---------------------------

export async function getAllTreedogs({
  accessToken,
  query = { __t: { $ne: "Registrable" } },
  options = {
    populate: "sire dam organizations.organization",
  },
}) {
  const url = adminUrl(`/dogs/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function createNewTreedog(document, { accessToken }) {
  const url = adminUrl(`/dogs/new`);
  return request({ url, accessToken, body: { document } });
}

export async function deleteTreedog(_id, { accessToken }) {
  const url = adminUrl(`/dogs/byId/${_id}`);
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  ORGANIZATIONS  ---------------------------

export async function getAllOrganizations({
  accessToken,
  query = {},
  options = {
    populate: "parentOrg",
  },
}) {
  const url = adminUrl(`/organizations/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function getOrganizationById(
  _id,
  { accessToken, options = { populate: "parentOrg" } }
) {
  const url = adminUrl(`/organizations/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function updateOrganization(
  _id,
  update,
  { accessToken, options = {} }
) {
  const url = adminUrl(`/organizations/byId/${_id}`);
  return request({
    url,
    method: "PATCH",
    accessToken,
    body: { update, options },
  });
}

export async function createNewOrganization(document, { accessToken }) {
  const url = adminUrl(`/organizations/new`);
  return request({ url, accessToken, body: { document } });
}

export async function deleteOrganization(_id, { accessToken }) {
  const url = adminUrl(`/organizations/byId/${_id}`);
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  CERTIFICATES  ---------------------------

export async function getAllCertificates({
  accessToken,
  query = {},
  options = {
    populate: "organizations.organization",
  },
}) {
  const url = adminUrl(`/certificates/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function getCertificateById(
  _id,
  { accessToken, options = { populate: "organizations.organization" } }
) {
  const url = adminUrl(`/certificates/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function updateCertificate(
  _id,
  update,
  { accessToken, options = {} }
) {
  const url = adminUrl(`/certificates/byId/${_id}`);
  return request({
    url,
    method: "PATCH",
    accessToken,
    body: { update, options },
  });
}

export async function createNewCertificate(document, { accessToken }) {
  const url = adminUrl(`/certificates/new`);
  return request({ url, accessToken, body: { document } });
}

export async function deleteCertificate(_id, { accessToken }) {
  const url = adminUrl(`/certificates/byId/${_id}`);
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  RESULT PROVIDERS  ---------------------------

export async function getAllResultproviders({
  accessToken,
  query = {},
  options = {},
}) {
  const url = adminUrl(`/resultproviders/query`);
  return request({ url, accessToken, body: { query, options } });
}

export async function getResultproviderById(
  _id,
  { accessToken, options = {} }
) {
  const url = adminUrl(`/resultproviders/byId/${_id}`);
  return request({ url, accessToken, body: { options } });
}

export async function updateResultprovider(
  _id,
  update,
  { accessToken, options = {} }
) {
  const url = adminUrl(`/resultproviders/byId/${_id}`);
  return request({
    url,
    method: "PATCH",
    accessToken,
    body: { update, options },
  });
}

export async function createNewResultprovider(document, { accessToken }) {
  const url = adminUrl(`/resultproviders/new`);
  return request({ url, accessToken, body: { document } });
}

export async function deleteResultprovider(_id, { accessToken }) {
  const url = adminUrl(`/resultproviders/byId/${_id}`);
  return request({ url, method: "DELETE", accessToken });
}
