import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accessTokenState,
  loadingState,
  snackBarState,
} from "recoil/globalStates";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import {
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Upload } from "@mui/icons-material";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

// Local
import { addTmpAttachment, deleteTmpAttachment } from "api/privateRoutes";
import {
  iconStyle
} from "constants/helpers";

export default function TreedogInformationImages({ formMethods }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const accessToken = useRecoilValue(accessTokenState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const {
    formState: { errors },
  } = formMethods;

  const [formData, setFormData] = useRecoilState(treedogAdditionState);
  const images = formData.attachments.images;

  const handleImageAdd = async (e) => {
    setLoading(true);
    const { name, files } = e.target;

    // Create objects with file metadata and content
    let newImages = [];
    for (const file of files) {      
      // If there is already an entry in newImages with the same name, error
      if (images.find((image) => image.name === file.name)) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: t(`File ${file.name} already added.`),
        });
        setLoading(false);
        return;
      }

      // Create FormData object for attachment file
      // and save it to blob storage
      const newAttach = new FormData();
      newAttach.append("attachment", file);
      let url = null;
      try {
        url = await addTmpAttachment(newAttach, {accessToken});
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: t("Failed to upload image:") + " " + error.message,
        });
        setLoading(false);
        return;
      }

      newImages.push({
        url: url,
        name: file.name,
        type: file.type,
        primary: false,
      });
    }

    // Append newImages to formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        images: [
          ...prevFormData.attachments.images,
          ...newImages,
        ],
      },
    }));

    setLoading(false);
  };

  const handleImageDelete = async (index) => {
    setLoading(true);
    const link = formData.attachments.images[index].url;
    try {
      await deleteTmpAttachment(link, {accessToken});
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: t("Failed to delete image from storage:") + " " + error.message,
      });
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        images: [
          ...images.slice(0, index),
          ...images.slice(index + 1),
        ],
      },
    }));
    setLoading(false);
  };

  const handlePrimaryChange = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        images: [
          ...images.map((image, i) => ({
            ...image,
            primary: i === index,
          })),
        ],
      },
    }));
  };

  /* Main */

  return (
  <Grid container>
    <Grid item xs={12} sm={12}>
      <Box mb={2}>
          {formData.attachments.images?.length == 0 && (
            <Typography variant="body">{t("No images added yet.")}</Typography>
          )}
          {formData.attachments.images?.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell></TableCell>
                  <TableCell>{t("File")}</TableCell>
                  <TableCell>{t("Primary?")}</TableCell>
                  <TableCell></TableCell>
                </TableHead>
                <TableBody>
                {formData.attachments.images?.map((image, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <img src={image.url} alt={image.name} style={{ width: '100px', height: '100px', objectFit: 'cover' }} /> 
                    </TableCell>
                    <TableCell>{image.name}</TableCell>
                    <TableCell>
                      <Radio
                        checked={image.primary}
                        onChange={() => handlePrimaryChange(index)}
                        name="primaryImage"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleImageDelete(index)}>
                        <FontAwesomeIcon icon={faTrash} style={iconStyle} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
          <Box>
            {lessThanSmall ? (
              <Fab color="primary" size="small" component="label">
                <Upload />
                <input
                  hidden
                  multiple
                  accept="image/*"
                  name="profileImage"
                  type="file"
                  onChange={handleImageAdd}
                />
              </Fab>
            ) : (
              <Button variant="contained" component="label">
                {t("Add images")}
                <input
                  hidden
                  multiple
                  accept="image/*"
                  name="profileImage"
                  type="file"
                  onChange={handleImageAdd}
                />
              </Button>
            )}
          </Box>
      </Grid>
    </Grid>
  );
}
