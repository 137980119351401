import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// MUI
import { Paper } from "@mui/material";
import { imageServer } from "constants/values";
import defaultImgUrl from "images/placeholder.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Local
import { getAttachments } from "api/publicRoutes";

export default function PhotoModal({ dog }) {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAttachments({ query: { parentDoc: dog._id } }).then((data) => {
      const imageList = [];
      data.sort((a, b) => {
        if (a.dateAdded < b.dateAdded) return -1;
        if (a.dateAdded > b.dateAdded) return 1;
        return 0;
      });

      // Push primary image or default image first
      const primaryImage = dog.primaryImage ?
        imageServer + "/images/" + dog.primaryImage :
        defaultImgUrl;
      imageList.push(primaryImage);

      // Push other images, excluding the primary image
      data.forEach((attachment) => {
        const imageUrl = imageServer + "/" + attachment.path + "/" + attachment.fileName;
        if (attachment.__t === "ImageAttachment" && imageUrl !== primaryImage) {
          imageList.push(imageUrl);
        }
      });

      setImages(imageList);
      setLoading(false);
    });
  }, [dog]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
  };

  return (
    <Paper sx={{ width: "100%", height: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
      {images.length > 1 ? (
        <Slider {...settings} style={{ width: "100%" }}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image of ${dog?.name}`}
              style={{ maxWidth: "100%", maxHeight: "80vh", objectFit: "contain" }}
            />
          ))}
        </Slider>
      ) : (
        <Paper
          component="img"
          src={images[0]}
          alt={`Image of ${dog?.name}`}
          sx={{ width: "100%", height: "auto", display: "flex" }}
        />
      )}
    </Paper>
  );
}