import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

import { Typography, Divider, Grid, Box } from "@mui/material";

import FDDLogo from "images/fdd_logo_bl.png";
import FDCLogo from "images/fdc_logo_withouttext.png";
import { baseDomain } from "constants/values";

// Recoil
import { useRecoilValue } from "recoil";
import {
  kruValuesState,
} from "recoil/globalStates";
import { getOrgByIdState } from "recoil/publicStates";

import { displayDate, getNameByLang } from "constants/helpers";
import PedigreeTable from "../pedigreeComponents/PedigreeTable";
import { smallImageNode } from "../pedigreeComponents/nodeElements";

// If we want to use function component, we need to pass the ref with forwardRef here,
// since react-to-print uses ref to fetch the underlying DOM

const CertificateTemplate = forwardRef(function CertificateTemplate(
  { dog, orgWithReg },
  ref
) {
  const { t } = useTranslation();
  const breeder = dog?.breeder;
  const owners = dog?.owners || [];
  const breederName = breeder ? breeder.breedingPrefix : breeder?.name;
  const ownerNames = owners.map((owner) => owner.name).join(", ");
  const ownerHeader = owners.length > 1 ? t("Owners") : t("Owner");

  const organization = useRecoilValue(getOrgByIdState(orgWithReg.organization));
  const kruValues = useRecoilValue(kruValuesState);
  const regClass = getNameByLang(kruValues
    .filter((i) => i._id === orgWithReg.regClass)?.[0]);

    const bgStyle = {
    opacity: 0.05,
    position: "absolute",
    left: 0,
    top: "285px",
    width: "100%",
    height: "auto",
  };

  const wrapperStyle = {
    overflow: "hidden",
    position: "relative",
    minHeight: "1000px",
    maxHeight: "1000px",
  };

  return (
    <>
      <div ref={ref} style={wrapperStyle}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          sx={{ p: 1 }}
        >
        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", p: 1 }}>
          <img style={{ height: "40px" }} src={FDDLogo} alt="FDD Logo" />
        </Box>
          <Grid item xs={12}>
            <Divider sx={{ mt: 1, mb: 1 }}>
              <Typography align="center" variant="h1">
                {organization.name}
              </Typography>
              <Typography align="center" variant="h6">
                {t("dogsRegistrationCertificate")}
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">{t("dogsName")}</Typography>

            <Typography variant="h5">{dog?.name || t("noData")}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">{t("dob")}</Typography>
            <Typography variant="h5">
              {displayDate(dog?.dateOfBirth)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">{t("sex")}</Typography>
            <Typography variant="h5">{t(dog?.sex) || t("noData")}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="caption">{t("registrationNumber")}</Typography>
            <Typography variant="h5">
              {orgWithReg.regNumber || t("noData")}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="caption">{t("Registration class")}</Typography>
            <Typography variant="h5">
              {regClass || t("limited")}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="caption">{t("microchipNumber")}</Typography>
            <Typography variant="h5">
              {dog?.microchipNumber || t("noData")}
            </Typography>
          </Grid>

          {breederName && (
          <Grid item xs={12}>
            <Typography variant="caption">{t("breeder")}</Typography>
            <Typography variant="h5">{breederName}</Typography>
          </Grid>
          )}

          {ownerNames && (
          <Grid item xs={12}>
            <Typography variant="caption">{ownerHeader}</Typography>
            <Typography variant="h5">{ownerNames}</Typography>
          </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="caption">{t("Link")}</Typography>
            <Typography variant="h5">{`${baseDomain}/${organization.prefix}/dog/${orgWithReg.regNumber}`}</Typography>
          </Grid>

          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Divider textAlign="left">
              <Typography variant="h5">{t("pedigree")}</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <div id="treeWrapper" style={{ width: "100%", height: "500px" }}>
              <PedigreeTable
                dog={dog}
                nodeSize={{ x: 200, y: 40 }}
                translate={{ x: 0, y: 200 }}
                zoom={0.8}
                renderCustomNodeElement={(node) => smallImageNode({ node, organization })}
                />
            </div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography align="right" variant="caption" sx={{ mt: 2 }}>
                {t("printedFromFDDAt")} {dayjs().format("MM.DD.YYYY HH.mm")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <img style={bgStyle} src={FDCLogo} alt="" />
      </div>
    </>
  );
});

export default CertificateTemplate;
