import { atom, atomFamily, selectorFamily } from "recoil";
import { getLitterById } from "api/privateRoutes";
import { accessTokenState } from "./globalStates";

const sessionStorageEffect = key => ({ setSelf, onSet }) => {
  const savedValue = sessionStorage.getItem(key);
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet(newValue => {
    sessionStorage.setItem(key, JSON.stringify(newValue));
  });
};

export const dogRegistrationState = atom({
  key: 'dogRegistrationState',
  default: {
    activeStep: 0,
    registry: null,
    class: "reg.classes.full",
    owner: null,
    breeder: null,
    sire: null,
    dam: null,
    name: '',
    callname: null,
    embarkURL: '',
    microchipNumber: '',
    sex: 'male',
    dateOfBirth: null,
    countryOfOrigin: null,
    declaredBreed: '',
    declaredMix: '',
    declaredType1: '',
    declaredType2: '',
    color: null,
    whitemarkings: [],
    spots: [],
    othermarkings: [],
    alocusmodifiers: [],
    merlemodifiers: [],
    othermodifiers: [],
    coattype: null,
    coatcurliness: null,
    coatqualities: [],
    height: '',
    weight: '',
    heightIn: '',
    weightLb: '',
    dataVerified: false,
    orgAgreement: false,
    platformAgreement: false,
    attachments: {
      images: [],
      files: [],
      links: [],
      text: [],
      linkText: "",
      noteText: "",
    },
  },
  effects_UNSTABLE: [sessionStorageEffect('dogRegistrationState')],
});

export const treedogAdditionState = atom({
  key: 'treedogAdditionState',
  default: {
    registry: null,
    name: '',
    callname: null,
    embarkURL: '',
    microchipNumber: '',
    sex: 'male',
    dateOfBirth: null,
    countryOfOrigin: null,
    declaredBreed: '',
    declaredMix: '',
    declaredType1: '',
    declaredType2: '',
    color: null,
    whitemarkings: [],
    spots: [],
    othermarkings: [],
    alocusmodifiers: [],
    merlemodifiers: [],
    othermodifiers: [],
    coattype: null,
    coatcurliness: null,
    coatqualities: [],
    height: '',
    weight: '',
    heightIn: '',
    weightLb: '',
    dataVerified: false,
    orgAgreement: false,
    platformAgreement: false,
    attachments: {
      images: [],
      files: [],
      links: [],
      text: [],
      linkText: "",
      noteText: "",
    },
  },
  effects_UNSTABLE: [sessionStorageEffect('treedogAdditionState')],
});

export const litterRegistrationState = atom({
  key: 'litterRegistrationState',
  default: {
    activeStep: 0,
    registry: null,
    owner: null,
    breeder: null,
    sire: null,
    dam: null,
    breeder: null,
    name: '',
    embarkURL: '',
    dateOfBirth: null,
    countryOfOrigin: null,
    declaredBreed: '',
    declaredMix: '',
    declaredType1: '',
    declaredType2: '',
    puppies: [],
    dataVerified: false,
    orgAgreement: false,
    platformAgreement: false,
    attachments: {
      images: [],
      files: [],
      links: [],
      text: [],
      linkText: "",
      noteText: "",
    },
  },
  effects_UNSTABLE: [sessionStorageEffect('litterRegistrationState')],
});

export const existingLitterState = selectorFamily({
  key: "existingLitterState",
  get:
    (id) =>
    async ({ get }) => {
      const accessToken = get(accessTokenState);
      const response = await getLitterById(id, { accessToken });
      if (response?.error) {
        throw response;
      }
      return response;
    },
});

export const existingDogState = selectorFamily({
  key: "existingDogState",
  get:
    (id) =>
    async ({ get }) => {
      const accessToken = get(accessTokenState);
      const response = await getDogById(id, { accessToken });
      if (response?.error) {
        throw response;
      }
      return response;
    },
});

export const statsState = atom({
  key: "statsState",
  default: {
    mating: "",
    birth: "",
    deadPuppies: "",
  },
});

export const formErrorsState = atom({
  key: "formErrorsState",
  default: {},
});

export const userIsSireOwnerState = atom({
  key: "userIsSireOwnerState",
  default: null,
});

export const userIsDamOwnerState = atom({
  key: "userIsDamOwnerState",
  default: null,
});
