import React, { useState, useEffect } from "react";
import { Fab } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <Fab
          color="primary"
          size="small"
          onClick={scrollToTop}
          style={{
            backgroundColor: "#004569cf",
            color: "#ffffff",
            height: "45px",
            width: "45px",
            position: "fixed",
            bottom: "2rem",
            right: "2rem",
          }}
        >
          <KeyboardArrowUp />
        </Fab>
      )}
    </div>
  );
}

export default ScrollToTop;