import { query } from "@niitsolutions/kru/fetcher";
import { publicUrl, request } from "./common";

// ---------------------------- SPECIALS ------------------------------

export async function getDbStats() {
  const url = publicUrl(`/`);
  return request({ url, method: "GET" });
}

// ---------------------------- KRU VALUES ------------------------------

export async function getKruvalues({ query = {}, options = {} } = {}) {
  const url = publicUrl(`/kruvalues`);
  return request({ url, body: { query, options } });
}

export async function getKruValuesByCategory(
  category,
  { options = { limit: 0 } } = {}
) {
  return getKruvalues({ query: { category: { $in: category } }, options });
}

// ---------------------------- RESULT PROVIDERS ------------------------------

export async function getResultProviders({ query = {}, options = {} } = {}) {
  const url = publicUrl(`/resultproviders`);
  return request({ url, body: { query, options } });
}

// ---------------------------------- DOGS ------------------------------

export async function getTreedogs({
  accessToken,
  query = { __t: { $ne: "Registrable" } },
  options = {
    populate: "organizations.organization",
  },
}) {
  const url = publicUrl(`/dogs`);
  return request({ url, body: { query, options } });
}

export async function getDogs(
  {
  query = { __t:"Registrable", pendingRegistration: false },
  options = {
    populate:
      "organizations.organization",
  },
  } = {}) {
  const url = publicUrl(`/dogs`);
  return request({ url, body: { query, options } });
}

export async function getOrganizationDogs(
  {
  org = { org },
  options = {
    populate:
      "organizations.organization",
  },
 }) {
  if (! org ) { return null; }
  const url = publicUrl(`/dogs`);
  const query = { __t:"Registrable",
    pendingRegistration: false,
    'organizations.organization': { $in: [org._id] }};
  return await request({ url, body: { query, options } });
}

export async function getPendingDogs(
  {
  query = { __t:"Registrable", pendingRegistration: true },
  options = {
    populate:
      "organizations.organization",
  },
  } = {}) {
  const url = publicUrl(`/dogs`);
  return request({ url, body: { query, options } });
}

export async function getDogByOrgRegNumber(
  {
    organization = {}, regNumber = '',
    options = {
      populate:
        "breeder owners sire dam organizations.organization certificates.certificate",
    },
  } = {}) {
  const url = publicUrl(`${organization.prefix}/dog/${regNumber}`);
  return request({ url, body: { options } });
}

export async function getDogsBySex(sex) {
  return getDogs({ query: { sex } });
}

// TODO eventually when we have health testing running,
// remove this and require use of the admin interface
export async function getAttachments({ query }) {
  const url = publicUrl(`/attachments`);
  return request({ url, body: { query } });
}

// ----------------------------- ORGANIZATIONS ------------------------------

export async function getAllOrganizations({ query = {}, options = {} } = {}) {
  const url = publicUrl(`/organizations`);
  return request({ url, body: { query, options } });
}

export async function getOrganizationById(_id, { options = {} } = {}) {
  if (!_id) return;
  if (typeof _id !== 'string') {
    throw new Error ("getOrganizationById requires ID in string format");
  }
  const url = publicUrl(`/organizations/byId/${_id}`);
  return request({ url, body: { options } });
}

export async function getOrganizationByPrefix(_prefix, { options = {} } = {}) {
  if (!_prefix) return;
  if (typeof _prefix !== 'string') {
    throw new Error ("getOrganizationByprefix requires prefix in string format");
  }
  const url = publicUrl(`/organizations`);
  const orgArray = await request({ url, body: {
    "query": {
      "prefix": _prefix
    }
  } });

  if (orgArray.length > 0) {
    return orgArray[0];
  }
  return null;
}

// ----------------------------- CERTIFICATES ------------------------------

export async function getCertificateById(_id, { options = {} } = {}) {
  const url = publicUrl(`/certificates/byId/${_id}`);
  return request({ url, body: { options } });
}

// ---------------------------------- USER ------------------------------

export async function getAllUsers( { body = {} } = {}) {
  const url = publicUrl(`/users`);
  return request({ url, body});
}

export async function getBreederByQuery( { body = {} } = {}) {
  const url = publicUrl(`/users`);
  return request({ url, body});
}

export async function getBreederById(_id, { options = {} } = {}) {
  const url = publicUrl(`/users/byId/${_id}`);
  return request({ url, body: { options } });
}

export async function getBreedersWithLitters({ options = {} } = {}) {
  const url = publicUrl(`/breedersWithLitters`);
  return request({ url, body: { options } });
}

// ---------------------------------- LITTERS ------------------------------

export async function getLitters({ query = {}, options = {} } = {}) {
  const url = publicUrl(`/litters`);
  return request({ url, body: { query, options } });
}

export async function getOrganizationLitters(
  {
  org = { org },
  options = {
    populate:
      "breeder owners sire dam organizations.organization",
  },
 }) {
  const url = publicUrl(`/litters`);
  query = {
    // __t: null,
    'organizations.organization': { $in: [org._id] }
  };
  return await request({ url, body: { query, options } });
}

export async function getLitterById(_id,
  { options = {
    populate: "sire dam organizations.organization certificates.certificate certificates.organization"
   } } = {}) {
  const url = publicUrl(`/litters/byId/${_id}`);
  return request({ url, body: { options } });
}

export async function getLittersByBreederId(id) {
  const url = publicUrl(`/litters`);
  return request({
    url,
    body: {
      options: {populate: "sire dam organizations.organization certificates.certificate"},
      query: { breeder: id }
    } });
}

export async function getLittersByParentId(id, sex) {
  const url = publicUrl(`/litters`);
  const key = sex === "female" ? "dam" : "sire";
  return request({
    url,
    body: {
      options: {populate: "sire dam organizations.organization certificates.certificate"},
      query: { [key]: id } } });
}

export async function getLitterBySiblingId(_id) {
  const url = publicUrl(`/litters`);
  return request({
    url,
    body: {
      options: {populate: "sire dam organizations.organization certificates.certificate"},
      query: { puppies: _id } } });
}

// ---------------------------------- PEDIGREE ------------------------------

export async function getDogById(_id, { options = {populate: "sire dam organizations.organization"} } = {}) {
  const url = publicUrl(`/dogs/byId/${_id}`);
  return await request({ url, body: { options } });
}

export async function getDogWithPedigree(_id, maxDepth = 4) {
  return getDogById(_id, {
    options: { autopopulate: true, maxDepth, lean: false },
  });
}

// ---------------------------------- SEARCH ------------------------------

export async function getSearchResults(query, options = {}) {
  const url = publicUrl(`/dogs`);
  return request({
    url,
    body: { options, query: { $text: { $search: query } } },
  });
}

// ---------------------------------- RESULT PROVIDERS ------------------------------

export async function getResultproviders({ query = {}, options = {} } = {}) {
  const url = publicUrl(`/resultproviders`);
  return request({ url, body: { query, options } });
}

export async function getResultProviderById(_id, { options = {} } = {}) {
  const url = publicUrl(`/resultproviders/byId/${_id}`);
  return request({ url, body: { options } });
}
