import React, { useEffect, useState } from "react";
import {
  Controller,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accessTokenState,
  kruValuesState,
  loadingState,
  snackBarState,
} from "recoil/globalStates";
import { treedogAdditionState } from "recoil/registrationStates";

// MUI
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

// Local
import { parseEmbarkPage } from "api/privateRoutes";
import {
  createHandleInputChange,
  getBreeds,
  getMixes,
  getTypes
} from "constants/helpers";

export default function TreedogInformationTesting({ formMethods }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const accessToken = useRecoilValue(accessTokenState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [inputValue, setInputValue] = useState('');

  const { control, setValue } = formMethods;
  const [formData, setFormData] = useRecoilState(treedogAdditionState);
  const handleInputChange = createHandleInputChange(setFormData);

  const {
    declaredBreed,
    declaredMix,
    declaredType1,
    declaredType2,
    embarkURL,
  } = formData;

  const kruValues = useRecoilValue(kruValuesState);
  const breeds = getBreeds(kruValues, language);
  const mixes = getMixes(kruValues, language);
  const types = getTypes(kruValues, language);
  const kruAncestry = kruValues.filter((value) => value.category === "ancestry");


  const preloadEmbark = async (event) => {  
    if (!embarkURL) {
      setSnackbarState({
        message: "Please enter a URL (such as http://embk.me/dogname)",
        severity: "error",
        open: true,
      });
      return;
    }
    preloadValues(await handleEmbarkURLChange(embarkURL));
    setFormData((prevFormData) => ({
      ...prevFormData,
      attachments: {
        ...prevFormData.attachments,
        links: [
          ...prevFormData.attachments.links,
          embarkURL
        ],
      },
    }));
  };

  const handleEmbarkURLChange = async (url) => {
    try {
      setLoading(true);
      let response = await parseEmbarkPage(url, { accessToken });
      setLoading(false);

      if (response.error) {
        let message = "Unable to fetch " + url + " - " + response.message;
        let note = "";
        if (response.message.includes("Only absolute URLs are supported"))
          note = " (Try including http:// or https://)";
        else if (response.message.includes("\"exp\" claim timestamp check failed"))
          note = " (Try reloading the page)";
        setSnackbarState({
          message: message + note,
          severity: "error",
          open: true,
        });
      }

      response.provider = "embark";
      // TODO consider making this an attachment immediately, or formdata or such
      response.source = "website";
      response.url = inputValue;

      setValue("embarkData", response);
      return(response);

    } catch (error) {
      setLoading(false);
      setSnackbarState({
        message: "Error in parsing " + inputValue + ": " + error.message,
        severity: "error",
        open: true,
      });
    }
  };

  const preloadValues = (data) => {
    if (!data) return;
    const userData = data.results.userData;
    const traits = data.results.traits;
    const sexEmbark = !userData.sex ? null :
      userData.sex.toLowerCase() === "f" ? "female" : "male";
    const dobEmbark = userData.dateOfBirth;
    const breedMixEmbark = userData.genotype.breedMix;

    setFormData({
      ...formData,
      ["name"]: userData.name,
      ["microchipNumber"]: userData.microchip,
      ["sex"]: sexEmbark,
      ["dateOfBirth"]: userData.dateOfBirth,
    });
  
    // So far as I can tell this never gets set
    // if (!dateOfBirth) setValue("dateOfBirth", dobEmbark);

    // traits: TODO
    /* {label: 'E Locus (MC1R)', result: 'No dark mask or grizzle (EE)'} -> mask or grizzle?
{label: 'K Locus (CBD103)', result: 'More likely to have a patterned haircoat (kyky)'}
{label: 'Intensity Loci', result: 'Any light hair likely yellow or tan (Intermediate Red Pigmentation)'}
{label: 'A Locus (ASIP)', result: 'Fawn Sable coat color pattern (ayay)'}
{label: 'D Locus (MLPH)', result: 'Dark areas of hair and skin are not lightened (DD)'}
{label: 'B Locus (TYRP1)', result: 'Black or gray hair and skin (BB)'}
{label: 'Saddle Tan (RALY)', result: 'Not expressed (NN)'}
{label: 'M Locus (PMEL)', result: 'No merle alleles (mm)'}
{label: 'H Locus (Harlequin)', result: 'No harlequin alleles (hh)'}
{label: 'Furnishings (RSPO2)', result: 'Likely unfurnished (no mustache, beard, and/or eyebrows) (II)'}
{label: 'Coat Length (FGF5)', result: 'Likely long coat (LhLh)'}
{label: 'Coat Texture (KRT71)', result: 'Likely straight coat (CC)'}
{label: 'Hairlessness (FOXI3)', result: 'Very unlikely to be hairless (NN)'}
{label: 'Hairlessness (SGK3)', result: 'Very unlikely to be hairless (NN)'} */

    // If barWidthPercentage is 100, it's a purebred and we fill out breed
    // Otherwise we leave it all blank (but could in future make some educated guesses).
    if (!declaredBreed && !declaredMix && !declaredType1 && !declaredType2) {
      const breedWithFullBar = breedMixEmbark.find(entry => entry.barWidthPercentage === 100);
      if (breedWithFullBar) {
        setValue("declaredBreed", breeds.find(b => b.en.name === breedWithFullBar.breedName));
      }
    }
  };


  return (
    <Grid item xs={12} sx={{ mb: 6 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="body">
          {t("Enter the URL of the dog's Embark test results, if any. We ask for this first so we can use it to preload the rest of your dog's information.")}
        </Typography>
        <Controller
          name="embarkURL"
          control={control}
          defaultValue={formData.embarkURL || ""}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              value={formData.embarkURL}
              onChange={(e) => handleInputChange(e, field)}
              label={t("Enter URL")}
              sx={{ mt: 2 }}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={preloadEmbark}
          sx={{ mt: 2 }}
        >
          {t("Preload registration data")}
        </Button>
      </Paper>
    </Grid>
  );
}
